import {
  Component, ComponentRef, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, QueryList,
  SimpleChanges,
  ViewChild, ViewChildren
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn
} from "@angular/forms";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {IGift, IRedeemItem, IRedeemOrder, User} from "../../models";
import {AppComponent} from "../app.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgForOf, NgIf} from "@angular/common";
import {compareFunc} from "../../helpers/compareFunc";
import {NumberCommasPipe} from "../../helpers/number_commas.pipe";
import {LocalDateformatPipe} from "../../helpers/local-dateformat.pipe";
import {first, Subscription} from "rxjs";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltip, MatTooltipModule} from "@angular/material/tooltip";

interface initSelectBoxOptions {
  forceReloadAll : boolean,
  giftBrandUpdated : boolean,
  giftTypeUpdated : boolean,
  giftRangeUpdated : boolean,
}

@Component({
  selector: 'app-redeem-add-items',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    NgForOf,
    NgIf,
    NumberCommasPipe,
    LocalDateformatPipe,
    MatButtonModule,
    MatTooltipModule
  ],
  templateUrl: './redeem-add-items.component.html',
  styleUrl: './redeem-add-items.component.css',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedeemAddItemsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() orderAction!: 'new' | 'edit' | null
  @Input() order!: Partial<IRedeemOrder> | undefined
  @Input() giftsArray!: Partial<IGift>[]
  @Output() childEvent = new EventEmitter<any>()

  @ViewChildren('itemUpdateButton', {read: ElementRef}) itemUpdateButtonsRef: QueryList<ElementRef> | undefined
  @ViewChildren('itemUpdateButton', ) itemUpdateButtons: QueryList<MatTooltip> | undefined

  // @ViewChildren('testx1', {read: ElementRef}) testx1: QueryList<ElementRef> | undefined
  // @ViewChildren('testx1') testx1a: QueryList<MatTooltip> | undefined
  //
  // onTestClick() {
  //   // const x1 = this.testx1?.find(el => el.nativeElement.data.id === 'x1')
  //   const idx=this.testx1?.toArray().findIndex(el => el.nativeElement.dataset.id === 'x2')
  //   const x = this.testx1a?.get(<number>idx)
  //
  //   console.log('testx1=%o', idx)
  //   x?.show()
  // }

  title = ''
  error_msg = '';
  info_msg = '';
  order_error_msg = '';
  form: FormGroup;

  subscription =new Subscription()

  loading = false;
  submitted = false;
  user: User = this.appComponent.userService.currentUserValue;

  ordersRecs: Partial<IRedeemOrder>[] = [];
  orgOrderStatus : string|undefined = '';  // set when user edit order
  totalGiftOrderPoints =0
  orderStatusOptions = ['new','cancelled','processing','done']
  itemStatusOptions : string[] = ['','cancelled','reserved','delivered']

  orderQtyOptions = Array(20).fill(1).map((_,i) => i+1)

  // for selecting product
  // each element: {display, val}
  giftBrandOptions: any[] = []
  giftTypeOptions: any[] = []
  giftNameOptions: any[] = []

  get f() {
    return this.form.controls;
  }

  get aliases() {
    return this.form.get('aliases') as FormArray
  }

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private translate: TranslateService,
  ) {
    this.form = this.formBuilder.group({
      giftBrand: [''],
      giftType: [''],
      giftPointsRangeFrom: [''],
      giftPointsRangeTo: [''],
      giftName: [''],
      giftQty: ['1'],

      orderStatus: [],
      remarks: [],
      itemName: [],
      itemQty: [],
      aliases: this.formBuilder.array([]),
    });

    this.subscription.add(appComponent.userService.currentUser.subscribe(u => {
      this.user = u;
      // this.initBrandFilter();
      // this.initGiftsOptions();
    }));

  }

  removeAliasItems() {
    this.form.removeControl('aliases');
    this.form.addControl('aliases', this.formBuilder.array([]));
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('>>> RedeemAddItemsComponent.ngOnChanges: changes=', changes)

    for(let objName in changes) {
      switch(objName) {
        case 'orderAction':
          this.title = this.translate.instant('caption.redeem_gift_' +this.orderAction);
          this.totalGiftOrderPoints =0

          switch(this.orderAction) {
            case 'new':
              this.form.setValidators([ this.newGiftOrderFormVaidator() ])
              break

            case 'edit':
              this.form.setValidators(null)
              break
          }

          // console.log('>>> objname=%s, firstChange=%s, formValidatorSet=%s',
          //   objName, changes[objName].firstChange, this.formValidatorSet)
          break;

        case 'giftsArray':
          this.initSelectBoxOptions({ forceReloadAll: true})
          break;

        case 'order':
          // console.log('>>> ngOnChanges: order.len=%s, %o', Object.keys(changes['order'].currentValue).length, changes['order'].currentValue)

          if(!changes['order'].currentValue || Object.keys(changes['order'].currentValue).length === 0
            || this.orderAction === 'new') {
            // ignore
            break
          }

          this.title = this.translate.instant('caption.redeem_gift_' +this.orderAction) + ` [ <i>${this.order?.orderNum}</i> ]`;
          this.totalGiftOrderPoints =0
          this.orgOrderStatus = this.order?.status;
          this.f['orderStatus'].setValue(this.orgOrderStatus);
          this.f['remarks'].setValue(this.order?.remarks);

          if(this.orgOrderStatus !== 'done') this.form.addValidators([this.checkOrderStatusValidator()]);

          // clear all previously shown order items
          this.removeAliasItems()

          // show all items of selected order
          if(this.order?.items) {
            for (let item of this.order.items) {
              const itemForm = this.formBuilder.group({
                id: [item.id],
                createdOn: [item.createdOn],
                processedBy: [item.processedBy],
                processedOn: [item.processedOn],
                name: [ item.name ],
                points: [ item.points ],
                qty: [item.qty],
                status: [item.status ?? ''],
                orgStatus: [item.status ?? ''],
                disableButtonTooltip: [ false ],
              })
              this.aliases.push(itemForm);
            }
          }
          this.form.updateValueAndValidity()
          window.scrollTo(0, 0)

          break
      }
    }

  }

  ngOnInit(): void {
  }

  initSelectBoxOptions( options : Partial<initSelectBoxOptions> ) {
    let giftBrand = this.f['giftBrand'].value,
      giftType = this.f['giftType'].value,
      giftName = this.f['giftName'].value,
      giftPointsFrom = this.f['giftPointsRangeFrom'].value ? Number(this.f['giftPointsRangeFrom'].value.replace(/[^\d]/g, '')) : 0,
      giftPointsTo = this.f['giftPointsRangeTo'].value ? Number(this.f['giftPointsRangeTo'].value.replace(/[^\d]/g, '')) : Number.MAX_VALUE

    if(Number.isNaN(giftPointsFrom)) giftPointsFrom =0
    if(Number.isNaN(giftPointsTo)) giftPointsTo =Number.MAX_VALUE

    let giftBrandOptions =undefined, giftTypeOptions =undefined, giftNameOptions =undefined

    // console.log('>>> initSelectBoxOptions:', this.giftsArray.map( e => e.brand))

    if(options.forceReloadAll) {
      // console.log(this.giftsArray)

      // init this.giftBrandOptions
      const brandSet = new Set(this.giftsArray.map( e => e.giftbrand))
      giftBrandOptions = Array.from(brandSet)
        .sort((a, b) => compareFunc(<string>a, <string>b))
        .map(e => {
          return {display: e, val: e}
        })

      giftBrandOptions = [{display: this.translate.instant('caption.option_all'), val: ''}, ...giftBrandOptions]
      giftBrand = ''

      options.giftBrandUpdated = true
    }

    if(options.giftBrandUpdated) {
      // init this.giftTypeOptions
      const typeSet = new Set(this.giftsArray.filter(e => {
          return giftBrand === '' || giftBrand === e.giftbrand
        }).map( e => e.gifttype))
      giftTypeOptions = Array.from(typeSet)
        .sort((a, b) => compareFunc(<string>a, <string>b))
        .map(e => {
          return {display: e, val: e}
        })

      giftTypeOptions = [{display: this.translate.instant('caption.option_all'), val: ''}, ...giftTypeOptions]
      giftType = giftTypeOptions.length === 2 ? giftTypeOptions[1].val : ''

      // console.log('>>> giftTypeOptions.length=%s, choice=%s', giftTypeOptions.length, giftType)

      options.giftTypeUpdated = true
    }

      // init this.giftNameOptions
    if(options.giftTypeUpdated || options.giftRangeUpdated) {
      // init this.giftNameOptions
      giftNameOptions = this.giftsArray.filter(e => {
        return (giftBrand === '' || giftBrand === e.giftbrand)
          && (giftType === '' || giftType === e.gifttype)
          && <number>e.points >= giftPointsFrom && <number>e.points <= giftPointsTo
      }).map(e => {
        return {display: e.name, val: e.name}
      }).sort((a,b) => compareFunc(<string>a.val, <string>b.val))

      const firstItem = giftNameOptions.length === 0 ? 'caption.option_none' : 'caption.option_all'
      giftNameOptions = [{display: this.translate.instant(firstItem), val: ''}, ...giftNameOptions]
      giftName = giftNameOptions.length <= 1 ? giftNameOptions[giftNameOptions.length-1].val : ''
    }

    // update options
    if(giftBrandOptions !== undefined) this.giftBrandOptions = giftBrandOptions
    if(giftTypeOptions !== undefined) this.giftTypeOptions = giftTypeOptions
    if(giftNameOptions !== undefined) this.giftNameOptions = giftNameOptions

    // set the values back to this.f
    this.f['giftBrand'].setValue(giftBrand)
    this.f['giftType'].setValue(giftType)
    this.f['giftName'].setValue(giftName)
  }

  onChangeGiftBrand(event:any) {
    // console.log('>>> onChangeGiftBrand: %s', event.target.value)
    this.initSelectBoxOptions( { giftBrandUpdated: true } )
  }

  onChangeGiftType(event:any) {
    // console.log('>>> onChangeGiftType: %s', event.target.value)
    this.initSelectBoxOptions( { giftTypeUpdated: true } )
  }

  ngOnDestroy(): void {
    console.log('>>> RedeemAddItemsComponent.ngOnDestroy()')
    this.subscription.unsubscribe()
  }

  newGiftOrderFormVaidator() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // check giftPointsRangeFrom & giftPointsRangeTo fields are good

      // console.log('checkGiftPointsRange: %o', /^\d+$|^$/.test(this.f['giftPointsRangeFrom']?.value))

      const from_val =this.f['giftPointsRangeFrom']?.value?.replace(/[^\d]/g, '') ?? '',
        to_val =this.f['giftPointsRangeTo']?.value?.replace(/[^\d]/g, '') ?? ''

      let ok = true

      this.f['giftPointsRangeFrom']?.setErrors(null)
      this.f['giftPointsRangeTo']?.setErrors(null)
      this.f['giftName']?.setErrors(null)

      if(from_val.length > 0 && to_val.length > 0 && Number(from_val) > Number(to_val)) {
        ok =false
        this.f['giftPointsRangeFrom']?.setErrors({ range_error : {value:true} })
      }

      if(this.f['giftName']?.value.length === 0) {
        ok =false
        this.f['giftName']?.markAsTouched()
        // console.log('>>> newGiftOrderVaidator: %s', this.f['giftName']?.value.length)
        this.f['giftName']?.setErrors({ required: {value:true}} )
      }

      return ok ? null : { failed: {value:true}}
    }
  }

  onInputPointsRange(field_name:string) {
    const val = NumberCommasPipe.instant(this.f[field_name].value.replace(/[^\d]/g, ''))
    this.f[field_name].setValue(val)

    this.initSelectBoxOptions({ giftRangeUpdated:true })
  }

  findGift(name: string): Partial<IGift>|undefined {
    return this.giftsArray.find(gift => gift.name === name);
  }

  findOrder(orderNum: string|undefined) : Partial<IRedeemOrder>|undefined {
    if (!orderNum) return undefined;
    return this.ordersRecs.find(order => order.orderNum === orderNum);
  }

  onClickAddGiftToCart(event:any) {
    this.submitted =true;

    console.log('>>> onClickAddItemToCart: form.invalid=%s, giftName=%s', this.form.invalid, this.f['giftName'].value)

    if(this.form.invalid) return

    const chosenGift =this.findGift(this.f['giftName'].value);

    const fg =this.formBuilder.group({
      id: [Date.now()],
      createdOn: [new Date()],
      processedBy: [],
      processedOn: [],
      name: [ chosenGift?.name ],
      points: [ chosenGift?.points ],
      qty: [this.f['giftQty'].value],
      status: [null],
    })
    this.aliases.push(fg);

    this.totalGiftOrderPoints += (chosenGift?.points || 0) * this.f['giftQty'].value;
    // console.log('>>> aliases.len = %s', this.aliases.length)
    // this.form.updateValueAndValidity();

  }

  checkOrderStatusValidator() : ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      const orderStatus = this.f['orderStatus'].value;

      // clear the previous errors
      this.f['orderStatus'].setErrors(null);

      if(['cancelled', 'done'].indexOf(orderStatus) < 0) return null;

      // console.log('>>> checkOrderStatusValidator: this.aliases.controls=%s', this.aliases?.controls);

      // check if all items are either cancelled or delivered
      const someNotDone = this.aliases?.controls ?
        this.aliases.controls.find(item => {
          // console.log('checkOrderStatusValidator: %s:%s', item.get('name')?.value, item.get('orgStatus')?.value);
          if (['cancelled', 'delivered'].indexOf(item.get('orgStatus')?.value) < 0) {
            // console.log('checkOrderStatusValidator: return item=%o', item);
            return item;
          }
          return undefined;
        }) : undefined;

      // console.log('checkOrderStatusValidator: someNotDone=%s', someNotDone);

      if(someNotDone) {
        this.f['orderStatus'].markAsTouched({ onlySelf:true});
        this.f['orderStatus'].setErrors({'some_item_not_done': { value: true }})
      }

      return someNotDone ? {'some_item_not_done': { value: true }} : null;
    }
  }

  onChangeItemStatus(event: any, aliasPos:number) {
    console.log(">>> onChangeItemStatus= aliasPos=%s", aliasPos );

    const value = this.aliases.at(aliasPos)?.get('status')?.value;
    const orgValue = this.aliases.at(aliasPos)?.get('orgStatus')?.value;

    setTimeout(() => {
      // show tooltip for the update button

      // console.log('>>> onChangeItemStatus: itemUpdateButtons.len=%s', this.itemUpdateButtonsRef?.length)
      //const x = this.itemUpdateButtonsRef?.toArray()[0]

      // find the index of item update button
      const idx = this.itemUpdateButtonsRef?.toArray().findIndex(el => el.nativeElement.id === aliasPos.toString())

      // console.log('>>> onChangeItemStatus: idx=%o', idx)

      if(idx !== undefined) {
        const tooltip = this.itemUpdateButtons?.get(idx)

        try {tooltip?.show()} catch (e) {}

        setTimeout(() => { try {tooltip?.hide() }catch(e){} }, 1500)

        // console.log(">>> onChangeItemStatus= tooltip=%o", tooltip );
      }
    }, 200)
  }

  onClickUpdateItemStatus(aliasPos:number) {
    const newStatus =this.aliases.at(aliasPos)?.get('status')?.value;
    const itemId = this.aliases.at(aliasPos)?.get('id')?.value;
    console.log('onUpdateItemStatus: itemId=%s, pos=%s, status=%s', itemId, aliasPos, newStatus);

    this.submitted =true;
    this.loading =true;

    this.appComponent.rewardService.changeItemOrderStatus(this.order?.orderNum, itemId, newStatus)
      .pipe(first()).subscribe({
      next: (res) => {
        // done
        console.log('>>> onChangeItemOrderStatus: %o', res);

        if(res.ok) {
          // update points
          // this.reCheckSession();
          this.reqReloadData({reloadOrder: this.order?.orderNum})
        } else {
          this.order_error_msg = this.appComponent.translate.instant(res.result);
          this.submitted = this.loading = false;
        }
      }, error: err => {
        this.order_error_msg = this.appComponent.translate.instant('db.error.exception');
        this.submitted = this.loading = false;
      }
    });
  }

  onRemoveItem(event: any, removeIdx: number) {
    this.aliases.controls = this.aliases.controls.filter((fg, index) => {
      const found = index === removeIdx;
      if(found) {
        // return the points back
        this.totalGiftOrderPoints -= fg.get('points')?.value * fg.get('qty')?.value;
      }

      return !found;
    })
  }

  reCheckSession() {
    this.appComponent.userService.checkSession().pipe(first()).subscribe( () => {});
  }

  reqReloadData(options ?: any) {
    let events:any = {reloadData:true}
    if(options !== undefined) events = {...options, ...events}

    this.childEvent.emit(events)
  }

  onSubmit(event: any) {
    this.submitted = true
    if(this.form.invalid) return

    // console.log('>>> debug onSubmit: passed')
    // return;

    this.loading = true

    switch(this.orderAction) {
      case 'new':
        // submit new order to backend
        let items : Partial<IRedeemItem>[] = [];
        for(let alias of this.aliases.controls) {
          items.push({
            name: alias.get('name')?.value,
            points: alias.get('points')?.value,
            qty: alias.get('qty')?.value,
          })
        }

        this.appComponent.rewardService.addNewRedeemOrder(items)
          .pipe(first()).subscribe({
          next: (res) => {
            // done

            console.log('>>> redeem.onSubmit: %o', res);

            this.loading =false
            if(res.ok) {
              // remove all items
              this.removeAliasItems()

              // update points
              this.reCheckSession();

              this.reqReloadData()
            } else {
              this.order_error_msg = this.appComponent.translate.instant(res.result);
            }
          }, error: err => {
            this.loading =false
            this.order_error_msg = this.appComponent.translate.instant('db.error.exception');
          }
        });

        break;
    }
  }

  onClickUpdateOrder(event:any) {

    this.submitted =true;

    console.log('onClickUpdateOrder: %o', this.form['errors']);

    if(this.form.invalid) {
      return;
    }

    this.loading =true;

    this.appComponent.rewardService.changeOrderStatus(
      this.order?.orderNum, this.f['orderStatus'].value, this.f['remarks'].value)
      .pipe(first()).subscribe({
      next: (res) => {
        // done

        console.log('>>> changeOrderStatus: %o', res);

        if(res.ok) {
          // update points
          // this.reCheckSession();
          this.childEvent.emit({action: 'reloadData'})
          this.reqReloadData({closeOrderDisp: true})
        } else {
          this.order_error_msg = this.appComponent.translate.instant(res.result);
          this.submitted = this.loading = false;
        }
      }, error: err => {
        this.order_error_msg = this.appComponent.translate.instant('db.error.exception');
        this.submitted = this.loading = false;
      }
    });
  }
}
