import {inject} from "@angular/core";
import {UserService} from "../services";
import {Router} from "@angular/router";

export function authGuard(role:string) {
  return () => {
    // console.log('>>> authGuard');

    const userService = inject(UserService);
    const router = inject(Router);

    //debug
    // return true;

    const user = userService.currentUserValue;

    if(user.hasRole(role)) {

      // setTimeout(() => {
      //   console.log('authGuard: hasRole(%s)', role);
      // }, 1000)
      return true;
    }

    // setTimeout(() => {
    //   console.log('authGuard: failed', role);
    // }, 1000)

    if(!user.email) router.navigateByUrl('/login');
    return false;
  }
}
