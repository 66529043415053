<form class="mt-3 text-center" [formGroup]="form" method="post" >
  <h2 class="form-signin-heading text-center">{{ 'caption.redeem_gift' | translate}}</h2>

  <app-redeem-add-items *ngIf="orderAction != null"
    [orderAction]="orderAction"
    [order]="orderToEdit"
    [giftsArray]="giftsArray"
    (childEvent) = "onChildEvent($event)"
    ></app-redeem-add-items>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert"
       [innerHTML]="error_msg">
  </div>

  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg">
  </div>

  <hr class="my-3" />

  <div class="mt-1 text-center" >
    <div class="m-2 text-sm-start" >
      <mat-form-field class="recFilter_select">
        <mat-label [innerHTML]="'caption.filter'|translate"></mat-label>
        <select id="recFilter" formControlName="recFilter"
                    matNativeControl
                    (change)="changeRecFilter($event)">
          <option *ngFor="let option of recFilterOptions" [value]="option.val" [innerHTML]="option.display"></option>
        </select>
      </mat-form-field>

      <mat-form-field *ngIf="user.hasRole('ROLE_ADMIN')" class="mx-3">
        <mat-label [innerHTML]="'caption.brand'|translate"></mat-label>
        <select *ngIf="vendorBrandFilterOptions.length > 1" id="brandFilter" formControlName="brandFilter"
                matNativeControl
                (change)="changeBrandFilter($event)">
          <option *ngFor="let option of vendorBrandFilterOptions" [value]="option.val" [innerHTML]="option.display"></option>
        </select>
      </mat-form-field>

    </div>
  </div>

  <div *ngIf="ordersRecs" class="mt-2">
    <table class="table table-secondary table-bordered" >
      <thead class="table-primary fw-bolder nrg-table-header">
        <tr>
          <td>#</td>
          <td >{{ 'caption.order_num' | translate}}</td>
          <td >{{ 'caption.createdOn' | translate}}</td>
          <td *ngIf="user.hasRole('ROLE_ADMIN')" >{{ 'caption.email' | translate}}</td>
          <td *ngIf="user.hasRole('ROLE_ADMIN')" >{{ 'caption.brand' | translate}}</td>
          <td *ngIf="user.hasRole('ROLE_ADMIN')" >{{ 'caption.processed_by' | translate}}</td>
          <td >{{ 'caption.order_status' | translate}}</td>
        </tr>
      </thead>

      <tbody class="nrg-table-body">
        @for(order_rec of ordersRecs; let i=$index; track order_rec.orderNum ) {
          <tr>
            <td>{{ i+1 }}</td>
            <td>
              @if(user.hasRole('ROLE_ADMIN')) {
                <a (click)="onEditGiftOrder(order_rec.orderNum)"
                   href="javascript:void(0)"
                >{{ order_rec.orderNum }}</a>
              } @else {
                <b>{{ order_rec.orderNum }}</b>
              }
            </td>
            <td>{{ order_rec.createdOn | localDateformat:'YYYY-MM-DD hh:mm A' }}</td>
            <td *ngIf="user.hasRole('ROLE_ADMIN')" >{{ order_rec.email}}</td>
            <td *ngIf="user.hasRole('ROLE_ADMIN')" >{{ order_rec.brand}}</td>
            <td *ngIf="user.hasRole('ROLE_ADMIN')"
                [innerHTML]="(order_rec.processedBy ?? '') + (order_rec.processedOn ? '<br>' + (order_rec.processedOn | localDateformat:'(YYYY-MM-DD hh:mm A)') : '')"
            >
            </td>
            <td>{{ order_rec.status }}</td>
          </tr>
          <tr>
            <td></td>
            <!-- items rows -->
            <td class="text-sm-start" [attr.colspan]="user.hasRole('ROLE_ADMIN') ? '7' : '4' " >
              <table class="table table-info table-borderless">
                <thead class="nrg-table-header fst-italic">
                <td></td>
                <td *ngIf="user.hasRole('ROLE_ADMIN')" >{{ 'caption.processed_by' | translate}}</td>
                <td >{{ 'caption.item_name' | translate}}</td>
                <td >{{ 'caption.points' | translate}}</td>
                <td >{{ 'caption.qty' | translate}}</td>
                <td >{{ 'caption.item_status' | translate}}</td>
                </thead>

                <tbody class="nrg-table-body">
                  @for(item_rec of order_rec.items; let j=$index; track item_rec.id) {
                  <tr>
                    <td>{{ j+1 }}</td>
                    <td *ngIf="user.hasRole('ROLE_ADMIN')"
                      [innerHTML]="(item_rec.processedBy ?? '') + (item_rec.processedOn ? '<br>' + (item_rec.processedOn | localDateformat:'(YYYY-MM-DD hh:mm A)') : '')"
                    >
                    </td>
                    <td>{{ item_rec.name }}</td>
                    <td>{{ item_rec.points }}</td>
                    <td>{{ item_rec.qty }}</td>
                    <td>{{ item_rec.status }}</td>
                  </tr>
                  }
                </tbody>
              </table>
            </td>
          </tr>
            }
      </tbody>

    </table>
  </div>

</form>



