import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  accessHttp: Subject<string> =new Subject();

  constructor() { }
}
