<form class="container  text-center" [formGroup]="form" method="post" >
  <h4 [innerHTML]="title"></h4>

  @if(!user.hasRole('ROLE_ADMIN')) {
    <div class="position-relative">
      <div class="text-sm-end text-danger" [innerHTML]="'caption.you_have_points' | translate: {points: user.points |number_commas} " ></div>
    </div>

    <div class="row align-items-center g-3" >
      <div class="col-auto" >
        <mat-form-field >
          <mat-label [innerHTML]="'caption.gift_brand'|translate"></mat-label>
          <select id="giftBrand" formControlName="giftBrand"
                  matNativeControl
                  (change)="onChangeGiftBrand($event)">
            <option *ngFor="let option of giftBrandOptions" [value]="option.val" [innerHTML]="option.display"></option>
          </select>
        </mat-form-field>
      </div>

      <div class="col-auto" >
        <mat-form-field >
          <mat-label [innerHTML]="'caption.gift_type'|translate"></mat-label>
          <select id="giftType" formControlName="giftType"
                  matNativeControl
                  (change)="onChangeGiftType($event)">
            <option *ngFor="let option of giftTypeOptions" [value]="option.val" [innerHTML]="option.display"></option>
          </select>
        </mat-form-field>
      </div>

      <div class="col-auto" >
        <mat-form-field >
          <mat-label [innerHTML]="'caption.gift_points_range_from'|translate"></mat-label>
          <input matInput id="giftPointsRangeFrom" formControlName="giftPointsRangeFrom"
                 (input)="onInputPointsRange('giftPointsRangeFrom')"
          >

          <mat-error *ngIf="submitted && f['giftPointsRangeFrom']?.['errors']?.['range_error']"
                     [innerHTML]="'error.points_from_cannot_be_larger_than_to' | translate">
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-auto" >
        <mat-form-field >
          <mat-label [innerHTML]="'caption.gift_points_range_to'|translate"></mat-label>
          <input matInput id="giftPointsRangeTo" formControlName="giftPointsRangeTo"
                 (input)="onInputPointsRange('giftPointsRangeTo')"
          >

        </mat-form-field>
      </div>

      <div class="col-auto" >
        <mat-form-field class="giftName_select">
          <mat-label [innerHTML]="'caption.gift_name'|translate"></mat-label>
          <select id="giftName" formControlName="giftName"
                  matNativeControl
          >
            <option *ngFor="let option of giftNameOptions" [value]="option.val" [innerHTML]="option.display"></option>
          </select>
          <mat-error *ngIf="submitted && f['giftName']['errors']?.['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-auto" >
        <mat-form-field >
          <mat-label [innerHTML]="'caption.order_qty'|translate"></mat-label>
          <select id="giftQty" formControlName="giftQty"
                  matNativeControl
          >
            <option *ngFor="let i of orderQtyOptions;" [value]="i" [innerHTML]="i"></option>
          </select>
        </mat-form-field>
      </div>

      <div class="col-auto text-center" >
        <button type="button" class="btn btn-sm btn-primary"
                [disabled]="this.submitted && form.invalid && f['giftName'].value"
                (click)="onClickAddGiftToCart($event)"
                [innerHTML]="'caption.add_to_cart'|translate"
        >
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        </button>

      </div>
    </div>
  }

  @if(aliases.length > 0) {
    @if(!user.hasRole('ROLE_ADMIN')) {
      <div class="position-relative">
        <div class="text-sm-start position-absolute start-0 fw-bold" [innerHTML]="'caption.cart'|translate"></div>

        <div class="text-sm-end position-absolute end-0 fst-italic"
             [innerHTML]="'caption.total_gifts_points' | translate:{points: totalGiftOrderPoints|number_commas} ">
        </div>
      </div>
      <div class="">&nbsp;</div>
    }

    <div formArrayName="aliases">
      <table class="table table-info table-bordered " >
        <thead class="nrg-table-header fw-semibold fst-italic">
        <td>#</td>
        <td *ngIf="user.hasRole('ROLE_ADMIN')" >{{ 'caption.processed_by' | translate}}
        </td>
        <td >{{ 'caption.item_name' | translate}}</td>
        <td >{{ 'caption.points' | translate}}</td>
        <td >{{ 'caption.qty' | translate}}</td>
        <td *ngIf="orderAction === 'edit'" >{{ 'caption.item_status' | translate}}</td>
        <td >{{ 'caption.action' | translate}}</td>
        </thead>

        <tbody class="nrg-table-body" >
          @for(alias of aliases.controls; let i=$index; track alias.value.id) {
            <tr [formGroupName]="i" >
              <td>{{ i+1 }}</td>
              <td *ngIf="user.hasRole('ROLE_ADMIN')"
                  [innerHTML]="alias.value.processedBy ? (alias.value.processedBy ?? '') + (alias.value.processedOn ? '<br>' + (alias.value.processedOn | localDateformat:'(YYYY-MM-DD hh:mm A)') : '') : '' "
              ></td>
              <td [innerHTML]="alias.value.name" ></td>
              <td [innerHTML]="alias.value.points" ></td>
              <td [innerHTML]="alias.value.qty" ></td>

              <td *ngIf="orderAction === 'edit'" class="text-sm-center align-items-center">
                @if(user.hasRole('ROLE_ADMIN')
                && (['delivered', 'cancelled'].indexOf(alias.value.orgStatus) < 0 && ['cancelled', 'done'].indexOf(orgOrderStatus ?? '') < 0)) {

                  <select class="form-select-sm" id="itemStatus{{i}}" formControlName="status"
                          (change)="onChangeItemStatus($event, i)" >
                    <option *ngFor="let option of itemStatusOptions" [value]="option" [innerHTML]="option"></option>
                  </select>
                } @else {
                  <span [innerHTML]="alias.value.status" ></span>
                }
              </td>

              <td class="text-sm-center align-items-center" >
                <!-- last action button at the row -->
                @if(orderAction === 'new') {
                  <button type="button" class="btn btn-sm btn-outline-secondary"
                          (click)="onRemoveItem($event, i)"
                          [innerHTML]="'caption.remove_item'|translate">
                  </button>
                } @else if(orderAction === 'edit' && ['cancelled', 'done'].indexOf(orgOrderStatus ?? '') < 0) {
                  <button *ngIf="['delivered', 'cancelled'].indexOf(alias.value.orgStatus) < 0 && alias.value.status !== alias.value.orgStatus "
                          #itemUpdateButton="matTooltip"
                          data-id="{{ i }}"
                          type="button" class="btn btn-sm btn-outline-secondary"
                          matTooltip="{{ 'caption.click_here_to_update_item'|translate }}"
                          matTooltipPosition="above"
                          matTooltipHideDelay="2000"
                          (click)="onClickUpdateItemStatus(i)"
                          [innerHTML]="'caption.update_item'|translate">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  </button>
                }
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    @if(user.hasRole('ROLE_ADMIN')) {
      <div class="row g-3 text-center align-items-center" >
        <div class="col-8">
          <mat-label [innerHTML]="'caption.remarks' | translate" ></mat-label>
          <textarea matInput id="remarks" formControlName="remarks"
                    [readonly]="order?.status === 'done' || order?.status === 'cancelled'"
                    class="mb-1 form-field-full "
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="3" cdkAutosizeMaxRows="7"
                    [readOnly]="['cancelled', 'done'].indexOf(orgOrderStatus ?? '') >= 0"
                    [placeholder]="'caption.write_remarks' | translate" ></textarea>
        </div>

        <div class="col-auto">
          @if(['cancelled', 'done'].indexOf(orgOrderStatus ?? '') < 0) {
            <span class="fw-bold text-sm-center" [innerHTML]="('caption.order_status'|translate) + ' :&nbsp;'"></span>

            <mat-form-field>
              <mat-label  [innerHTML]=" 'caption.order_status' | translate "></mat-label>
              <select id="orderStatus" formControlName="orderStatus" matNativeControl>
                <option *ngFor="let option of orderStatusOptions" [value]="option" [innerHTML]="option"></option>
              </select>

              <mat-error *ngIf="submitted && f['orderStatus']?.['errors']?.['some_item_not_done']">
                {{ 'error.some_item_not_done' | translate }}
              </mat-error>

            </mat-form-field>
          } @else {
            <span [innerHTML]="('caption.order_status' | translate) + '&nbsp; : &nbsp;' +orgOrderStatus" ></span>
          }
        </div>

        <div *ngIf="order_error_msg" class="col-12 text-sm-center" >
          <div id="order_error_msg" class="alert alert-danger mb-3" role="alert"
               [innerHTML]="order_error_msg">
          </div>
        </div>

        <div *ngIf="['cancelled', 'done'].indexOf(orgOrderStatus ?? '') < 0" class="col-12 text-sm-center">
          <button *ngIf="orderAction === 'edit'"
                  type="button" class="btn btn-sm btn-primary"
                  [disabled]="this.submitted && form.invalid"
                  (click)="onClickUpdateOrder($event)"
                  [innerHTML]="'caption.update_order'|translate"
          >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          </button>
        </div>

      </div>
    }

    <div class="form-field-full text-sm-center" *ngIf="!form.invalid && orderAction === 'new'" >
      <mat-error *ngIf="submitted && totalGiftOrderPoints > user.points"
                 [innerHTML]="'error.not_enough_gift_points' | translate">
      </mat-error>

      <button type="button" class="btn btn-sm btn-primary"
              [disabled]="totalGiftOrderPoints > user.points || form.invalid || aliases.controls.length === 0"
              (click)="onSubmit($event)"
              [innerHTML]="'caption.submit'|translate"
      >
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      </button>
    </div>

  }
</form>
