import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'number_commas',
  standalone: true
})
export class NumberCommasPipe implements PipeTransform {

  // date: string "2023-12-18T01:36:37.668Z"
  transform(numstr: string|number|undefined): string {
    return NumberCommasPipe.instant(numstr);
  }

  // get date/time according to globals.display_time_zone
  static instant(numstr: string|number|undefined): string {
    if(typeof numstr === "undefined") return '';
    if(typeof numstr === "number") numstr = numstr.toString();

    // check if numstr is number
    if(!numstr.match(/^[\d\s,]+$/)) return numstr;

    const valueNum =Number(numstr.replace(/[,\s]/g, ''));
    return Intl.NumberFormat('en-US').format(Number(valueNum))
  }

}
