<form [formGroup]="form" class="form-signin text-center" >

  <h2 class="form-signin-heading">{{ 'caption.approve_new_user' | translate}}</h2>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert" [innerHTML]="error_msg"></div>

  <div *ngIf="info_msg" id="message_div" class="alert alert-success mt-2 d-none" [innerHTML]="info_msg"></div>

  <div formArrayName="aliases" *ngIf="users" class="mt-2">
    <table class="table table-secondary table-bordered" >
      <thead class="table-primary fw-bold nrg-table-header">
      <tr>
        <td>#</td>
        <td [innerHTML]="'caption.createdOn' | translate"></td>
        <td [innerHTML]="'caption.email' | translate"></td>
        <td [innerHTML]="'caption.fullname' | translate"></td>
        <td [innerHTML]="'caption.mobile' | translate"></td>
        <td [innerHTML]="'caption.company' | translate"></td>
        <td [innerHTML]="'caption.brand' | translate"></td>
        <td [innerHTML]="'caption.isNew' | translate"></td>
        <td [innerHTML]="'caption.isEnabled' | translate"></td>
        <td [innerHTML]="'caption.role' | translate"></td>
        <td [innerHTML]="'caption.profile' | translate"></td>
<!--        <td [innerHTML]="'caption.isAccNonExpired' | translate"></td>-->
<!--        <td [innerHTML]="'caption.isAccNonLocked' | translate"></td>-->
<!--        <td [innerHTML]="'caption.isPasswordNonExpired' | translate"></td>-->
      </tr>
      </thead>

      <tbody class="nrg-table-body">

        @for(user of users; let i=$index; track user.email) {
          <tr>
            <td>{{ i+1 }}</td>
            <td>{{ user.createdOn | localDateformat:'YYYY-MM-DD (ddd) hh:mm A' }}</td>
            <td>{{ user.email}}</td>
            <td><a (click)="onClickUser(i.toString())" href="javascript:void(0)" >{{ user.fullName}}</a></td>
            <td>{{ user.mobile}}</td>
            <td>{{ user.company}}</td>
            <td>{{ user.brand}}</td>
            <td>{{ (user.isNew ? 'value.yes' : 'value.no') |translate }}</td>
            <td>{{ (user.isEnabled ? 'value.yes' : 'value.no') |translate}}</td>
            <td>{{ (user.isSuperuser ? 'value.role_superuser' : (user.isAdmin ? 'value.role_admin' : 'value.role_user')) | translate }}</td>
            <td>{{ user.profile}}</td>
<!--            <td>{{ (user.isAccNonExpired ? 'value.yes' : 'value.no') |translate }}</td>-->
<!--            <td>{{ (user.isAccNonLocked ? 'value.yes' : 'value.no') |translate }}</td>-->
<!--            <td>{{ (user.isPasswordNonExpired ? 'value.yes' : 'value.no') |translate }}</td>-->
          </tr>
          <tr *ngIf="user.email && userApprovalBlocks[i.toString()]">
            <td colspan="11">
              <div class="form-group d-inline-block text-sm-center my-3" style="width: 100%">
                <label [hidden]="approvalDone[i.toString()] && gAction[i.toString()] !== 'reject'"
                  for="alias-{{i}}" class="sr-only">{{ 'caption.reject_reason' | translate }}</label>
                <input class="form-control form-control-sm my-3" style="width: 100%"
                  [hidden]="approvalDone[i.toString()] && gAction[i.toString()] !== 'reject'"
                       type="text" id="alias-{{i}}" [formControlName]="i"
                       [ngClass]="{ 'is-invalid': submitted && aliases.get(i.toString())?.['errors'] }"
                       placeholder="{{ 'caption.provide_reject_reason' | translate }}"
                       maxlength="{{ _globals.maxReasonLength }}"
                />

                <div *ngIf="submitted && aliases.get(i.toString())?.['errors']" class="invalid-feedback error-box">
                  <div *ngIf="submitted && aliases.get(i.toString())?.['errors']?.['reasonEmpty']"
                       [innerHTML]="'error.reason_is_reqd' | translate"></div>
                </div>

                <div *ngIf="approvalResult[i.toString()]" class="alert-success"
                     [ngClass]="{ 'is-invalid': !approvalDone[i.toString()] }"
                     [innerHTML]="approvalResult[i.toString()] | translate">
                </div>

                <div>
                  <button [hidden]="approvalDone[i.toString()]" [disabled]="loading" class="btn btn-sm ms-3 btn-secondary btn-block" type="button" (click)="onSubmit('approve', i.toString(), user.email)">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{ 'caption.approve' | translate }}
                  </button>
                  <button [hidden]="approvalDone[i.toString()]" [disabled]="loading" class="btn btn-sm ms-3 btn-secondary btn-block" type="button" (click)="onSubmit('reject', i.toString(), user.email)">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{ 'caption.reject' | translate }}
                  </button>

                </div>
              </div>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</form>

