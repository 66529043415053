import {Component, OnInit} from '@angular/core';
import {NgIf} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {TextFieldModule} from "@angular/cdk/text-field";
import {AppComponent} from "../app.component";
import {ActivatedRoute, Router} from "@angular/router";
import {first} from "rxjs";
import {compareFunc} from "../../helpers/compareFunc";

@Component({
  selector: 'app-appsettings',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule
  ],
  templateUrl: './appsettings.component.html',
  styleUrl: './appsettings.component.css'
})
export class AppsettingsComponent implements OnInit {
  error_msg='';
  info_msg ='';
  form: FormGroup;
  loading = false;
  submitted = false;

  get f() { return this.form.controls; }

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute
  ) {
    this.form = this.formBuilder.group({
      settings: []
    });
  }

  reloadData() {
    this.loading = true;
    this.appComponent.systemService.getSettings()
      .pipe(first()).subscribe({
      next: (res) => {
        // done
        if(res.ok) {
          // console.log('>>> res = %o', res);

          this.f['settings'].setValue(res.result);
        } else {
          this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
        }
        this.loading =false;

      }, error: err => {
        this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
        this.loading =false;
      }
    });

  }

  ngOnInit(): void {
    this.reloadData();
  }

  onSubmit() {
    console.log('onSubmit: dirty=%s', this.f['settings'].dirty);

    this.error_msg = this.info_msg ='';
    this.submitted = true;

    const newSettings = this.f['settings'].value;
    if(!newSettings || newSettings.replace(/\s/g, '').length === 0) {
      this.error_msg = this.appComponent.translate.instant('error.all_settings_emptied');
      return;
    }

    this.loading = true;

    this.appComponent.systemService.updateSettings(newSettings)
      .pipe(first()).subscribe({
      next: (res) => {
        // done
        if(res.ok) {
          // console.log('>>> res = %o', res);

          this.info_msg = this.appComponent.translate.instant(res.result);
          this.reloadData();
        } else {
          this.error_msg = this.appComponent.translate.instant('error.settings_update_failed');
        }
        this.submitted = this.loading =false;
      }, error: err => {
        this.error_msg = this.appComponent.translate.instant('error.settings_update_failed');
        this.submitted = this.loading =false;
      }
    });

  }

}
