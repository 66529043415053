import { Component } from '@angular/core';
import {AppComponent} from "../app.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {globals} from "../../conf/globals";
import {first} from "rxjs";
import {IUser, User} from "../../models";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    NgIf,
    TranslateModule
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent {
  protected readonly _globals = globals;

  error_msg='';
  info_msg = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  user: User;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              ) {
    this.user = this.appComponent.userService.currentUserValue

    this.form = this.formBuilder.group({
      'fullName': [this.user.fullName, Validators.compose(
        [ Validators.required, Validators.maxLength(globals.maxFieldLength)]
      )],
      'mobile': [this.user.mobile, Validators.compose(
        [ Validators.required, Validators.minLength(this._globals.minFieldMobileLength)]
      )],
      'company': [this.user.company, Validators.compose(
        [ Validators.required, Validators.minLength(this._globals.minFieldMobileLength)]
      )],
    });

  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted =true;

    this.info_msg = this.error_msg = '';

    if(this.form.invalid) return;

    const userDetails: Partial<IUser> = {
      email: this.user.email,
      fullName: this.f['fullName'].value,
      mobile: this.f['mobile'].value,
      company: this.f['company'].value,
    }

    const email =this.f['email']?.value;
    this.appComponent.userService.updateUser(userDetails)
      .pipe(first()).subscribe({
      next: (res) => {
        // done
        if(res.ok) {
          // console.log('>>> res = %o', res);
          this.info_msg = this.appComponent.translate.instant(res.result);
          this.submitted = false;

          this.user.fullName = this.f['fullName'].value
          this.user.mobile = this.f['mobile'].value
          this.user.company = this.f['company'].value
        } else {
          this.error_msg = this.appComponent.translate.instant(res.result);
        }

        this.loading = false;
      }, error: err => {
        this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
        this.loading = false;
      }
    });

  }
}
