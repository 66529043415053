import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {map} from "rxjs";
import {IResultGiftsResp, IResultPointsResp, IResultRedeemOrdersResp, IResultResp} from "../models";

@Injectable({
  providedIn: 'root'
})
export class RewardService {

  constructor(
    private http:HttpClient,
  ) {

  }

  getAllPointsRec(brand:string|string[], conditions?: any, needUserFullName:boolean = false) {
    return this.http.post<any>(environment.api_url +'/getAllPointsRec',
      { brand: brand, conditions: conditions ?? [], needUserFullName },
      {withCredentials: true})
      .pipe(map<any, IResultPointsResp>(res => {
        // console.log('>>> getAllPointsRec: %o', res);

        return res;
      }));
  }

  updatePoints(id: string|undefined, points: number, remarks:string) {
    return this.http.post<any>(environment.api_url +'/updatePoints',
      { id, points, remarks },
      {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        console.log('>>> updatePoints: %o', res);

        // return: { status: ok, result: msg }. msg: id for i18n. It needs '{email: xxxx}' as i18n's parameter
        return res;
      }));
  }

  disputeInvoice(id: string|undefined, dispute_reason: string) {
    return this.http.post<any>(environment.api_url +'/disputeInvoice',
      { id, dispute_reason },
      {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        console.log('>>> disputeInvoice: %o', res);

        // return: { status: ok, result: msg }. msg: id for i18n. It needs '{email: xxxx}' as i18n's parameter
        return res;
      }));
  }

  settleDisputeInvoice(id: string|undefined, settle_dispute_reason: string) {
    return this.http.post<any>(environment.api_url +'/settleDisputeInvoice',
      { id, settle_dispute_reason },
      {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        console.log('>>> settleDisputeInvoice: %o', res);

        // return: { status: ok, result: msg }. msg: id for i18n. It needs '{email: xxxx}' as i18n's parameter
        return res;
      }));
  }

  getAllGiftsRec(brand: string|string[]) {
    return this.http.post<any>(environment.api_url +'/getAllGiftsRec',
      { brand: brand },
      {withCredentials: true})
      .pipe(map<any, IResultGiftsResp>(res => {
        // console.log('>>> getAllGiftsRec: %o', res);

        return res;
      }));

  }

  getAllRedeemOrdersRec(brand:string|string[], conditions?: any) {
    return this.http.post<any>(environment.api_url +'/getAllRedeemOrdersRec',
      { brand: brand, conditions: conditions ?? [] },
      {withCredentials: true})
      .pipe(map<any, IResultRedeemOrdersResp>(res => {
        // console.log('>>> getAllPointsRec: %o', res);

        return res;
      }));
  }

  // return: {ok: true, result: <i18n id>
  addNewRedeemOrder(items:any) {
    return this.http.post<any>(environment.api_url +'/addNewRedeemOrder',
      { items: items },
      {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        // console.log('>>> addNewRedeemOrder: %o', res);

        return res;
      }));
  }

  changeItemOrderStatus(orderNum:string|undefined, itemId:string, newStatus:string) {
    return this.http.post<any>(environment.api_url +'/changeItemOrderStatus',
      { orderNum: orderNum, itemId: itemId, newStatus: newStatus },
      {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        // console.log('>>> changeItemOrderStatus: %o', res);

        return res;
      }));
  }

  changeOrderStatus(orderNum:string|undefined,
                    newStatus:string, remarks:string|null) {
    return this.http.post<any>(environment.api_url +'/changeOrderStatus',
      { orderNum: orderNum, newStatus: newStatus, remarks: remarks },
      {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        // console.log('>>> changeOrderStatus: %o', res);

        return res;
      }));
  }

  requestMeeting(meetingDate:Date|null|undefined, salesman:string|null|undefined) {
    return this.http.post<any>(environment.api_url +'/requestMeetingSalesman',
      { meetingDate: meetingDate, salesman: salesman },
      {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        // console.log('>>> changeOrderStatus: %o', res);

        return res;
      }));
  }

  requestTransferPoints(targetEmail:string|null|undefined) {
    return this.http.post<any>(environment.api_url +'/requestTransferPoints',
      { targetEmail },
      {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        // console.log('>>> changeOrderStatus: %o', res);

        return res;
      }));
  }

}
