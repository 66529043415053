import {Component, OnInit} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {AppComponent} from "../app.component";
import {Router} from "@angular/router";
import {globals} from "../../conf/globals";
import {first} from "rxjs";
import {IResultResp} from "../../models";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {SystemService} from "../../services/SystemService";

@Component({
  selector: 'app-register-user',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    NgClass,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
  ],
  templateUrl: './register-user.component.html',
  styleUrl: './register-user.component.css'
})
export class RegisterUserComponent implements OnInit {
  protected readonly _globals = globals;

  error_msg='';
  info_msg = '';
  regUserForm: FormGroup;
  loading = false;
  verificationCodeReceived = false;
  submitted = false;
  showEnterVerifyCodeMsg =false;
  agreedTnC = false

  // received email verification code
  emailVerificationCode = '';

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private router: Router,
              public systemService: SystemService
  ) {

    // console.log('>>> LoginComponent.constructor()');

    this.regUserForm = this.formBuilder.group({
      'email': ['', Validators.compose(
        [ Validators.required, Validators.email, Validators.maxLength(globals.maxFieldLength)]
      )],
      'fullName': ['', Validators.compose(
        [ Validators.required, Validators.minLength(globals.minFieldNameLength) ]
      )],
      'mobile': ['', Validators.compose(
        [ Validators.required, Validators.minLength(globals.minFieldMobileLength) ]
      )],
      'company': ['', Validators.compose(
        [ Validators.required, Validators.minLength(globals.minFieldNameLength) ]
      )],
      'verificationCode': ['', Validators.compose(
        [ Validators.required, Validators.minLength(globals.emailVerificationCodeLen) ]
      )],
      'tnc': [''],
    });

    // disable verificationCode validator first
    this.f['verificationCode'].disable();

  }

  ngOnInit(): void {
    this.loading = true
    this.systemService.getTnC().pipe(first()).subscribe({
      next: (resp) => {
        this.f['tnc'].setValue(resp.result)
        this.loading = false
      }, error: (err) => {
      }
    })
  }

  get f() { return this.regUserForm.controls; }

  onGetVerficationCode() {
    this.error_msg = this.info_msg ='';
    this.submitted =true;

    console.log('>> regUserForm.invalid=%s', this.regUserForm.invalid);
    if(this.regUserForm.invalid) return;

    this.loading =true;

    this.appComponent.userService.sendVerificationCodeEmail(this.f['email']?.value.trim())
      .pipe(first()).subscribe({
        next: data=> {
        // console.log('## %o', data);

        if(data.ok) {
          this.emailVerificationCode = data.result; // encrypted verification code str
          this.verificationCodeReceived = true;
          this.f['verificationCode'].enable();
          this.showEnterVerifyCodeMsg =true;
        } else {
          // failed to receive verification code
          this.error_msg =this.appComponent.translate.instant('error.registration_failed') +'<br>'
            +this.appComponent.translate.instant(data.result, {'email': this.f['email']?.value.trim()});
        }

        this.loading =false;
      }, error: err => {
        this.loading =true;
        this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
      }
    });

  }

  onClickAgreeTnC() {
    this.agreedTnC =true
  }

  onFinalSubmit() {
    this.error_msg = this.info_msg ='';
    this.submitted =true;
    this.showEnterVerifyCodeMsg = false;

    if(this.regUserForm.invalid) return;

    this.loading =true;
    this.appComponent.userService.register(
      this.f['email']?.value.trim(),
      this.f['fullName']?.value.trim(),
      this.f['mobile']?.value.trim(),
      this.f['company']?.value.trim(),
      this.f['verificationCode']?.value,
      this.emailVerificationCode
      ).pipe(first()).subscribe({
        next: (res: IResultResp) => {
          // done
          if(res.ok) {
            // successful
            this.info_msg = this.appComponent.translate.instant(res.result);
            this.verificationCodeReceived = false;
            this.emailVerificationCode ='';
            this.f['verificationCode'].disable();
          } else {
            this.error_msg = this.appComponent.translate.instant(res.result, {email: this.f['email'].value});

            if(res.result === 'error.code_expired') {
              // restore the Register button
              this.verificationCodeReceived = false;
              this.emailVerificationCode ='';
              this.f['verificationCode'].disable();
            }
          }

          this.loading = this.submitted = false;
          this.f['verificationCode'].setValue('');

        }, error: (err:any) => {
          this.loading = this.submitted = false;

          const msg_key = err['error_msg'] ?? 'error.user_reg_failed';
          // console.log('>>> msg_key=%s', msg_key);
          this.error_msg = this.appComponent.translate.instant( msg_key );
        }
      }
    );

  }

}
