import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {NgForOf, NgIf} from "@angular/common";
import {StringReplacePipe} from "../../helpers/string-replace.pipe";
import {LocalDateformatPipe} from "../../helpers/local-dateformat.pipe";
import {AppComponent} from "../app.component";
import {Router} from "@angular/router";
import {HttpClient, HttpEventType, HttpResponse} from "@angular/common/http";
import {finalize, first} from "rxjs";
import {compareFunc} from "../../helpers/compareFunc";
import {IGift} from "../../models/Gifts";
import {environment} from "../../environments/environment";
import {IResultResp} from "../../models";

@Component({
  selector: 'app-update-gifts',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    NgIf,
    StringReplacePipe,
    NgForOf,
    FormsModule,
    LocalDateformatPipe
  ],
  templateUrl: './update-gifts.component.html',
  styleUrl: './update-gifts.component.css'
})
export class UpdateGiftsComponent implements OnInit {
  error_msg = '';
  info_msg = '';
  form: FormGroup;
  formFile: FormGroup;
  uploadProgress = 0;
  loading = false;
  submitted = false;
  lastUpdateTime:Date|undefined = new Date(0);

  giftsRecs: Partial<IGift>[] = [];

  // for brandFilter
  brandFilterOptions: any[] = [];

  fileGift: any = null;
  fileSize =0;

  get f() {return this.form.controls;}

  get f_File() {
    return this.formFile.controls;
  }

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private router: Router,
              private http: HttpClient,
  ) {
    this.formFile = this.formBuilder.group({
      'gifts_file': ['', Validators.required],
    });

    this.form = this.formBuilder.group({
      'brandFilter': [],
    });

  }

  changeBrandFilter(event: any) {
    const filterOption = event.target.value;
    console.log('>>> changeBrandFilter: %s', filterOption);

    this.reloadData();
  }

  ngOnInit(): void {
    this.initBrandFilter();
  }

  initBrandFilter() {
    ////////////////////////
    // brandFilter
    this.brandFilterOptions =[ {val:'*', display: this.appComponent.translate.instant('select.all_brands') } ];
    const userBrand = this.appComponent.user.brand;
    let defaultBrand ='*';
    if(typeof userBrand === 'object' && userBrand.length > 0) {
      // provide breakdown list
      for(let brand of userBrand) {
        this.brandFilterOptions.push({val: brand, display: brand});
      }
    }
    this.f['brandFilter'].setValue(defaultBrand);

    this.reloadData();
  }

  reloadData() {
    console.log('reloadData():')

    // set brands
    let brand = this.f['brandFilter'].value;

    if(brand === '*') {
      // conditions.push({var: 'brand', op: 'IN', value: this.appComponent.user.brand});
      brand =this.appComponent.user.brand;
    }

    // console.log('>>> reloadData:brand= %o', brand);

    this.appComponent.rewardService.getAllGiftsRec(brand)
      .pipe(first()).subscribe({
      next: (res) => {
        // done
        if(res.ok) {
          this.giftsRecs =res.gifts;

          this.giftsRecs = res.gifts.sort(
            (a, b) => {
              if (a.name && b.name) return compareFunc(a.brand +a.name, b.brand +b.name);
              return -1;
            });

          if(this.giftsRecs?.length > 0) {
            this.lastUpdateTime = this.giftsRecs[0].createdOn;
          }

          // console.log('>>> got giftsRecs = %o', this.giftsRecs);
        } else {
          this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
        }
      }, error: err => {
        this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
      }
    });

  }

  onSelectFile(event: Event) {
    try {
      this.fileGift = (event.target as HTMLInputElement)?.files?.[0];
      // console.log('onSelectFile: file = %o', this.fileGift);

      this.fileSize =(this.fileGift as File).size;
    }catch (e) {console.error(e)}
  }

  onUploadGifts() {
    this.submitted = true;
    this.info_msg = this.error_msg = '';

    this.loading = true;

    // console.log(">>> onUpload:")

    const fileSize = (this.fileGift as File).size;

    // create formData to upload file
    const formData = new FormData();
    formData.append('gifts_file', this.fileGift);

    this.http.post<any>(environment.api_url +'/uploadGiftsFile',
      formData, {withCredentials: true, reportProgress: true, observe: 'events'})
      .pipe(finalize(() => this.reset()))
      .subscribe({
        next: (event) => {
          // console.log('>>> event.type=%s, %o', event.type, event);

          if(event.type === HttpEventType.UploadProgress ) {
            let value = Math.round( 100 * event.loaded / (event.total ?? fileSize));
            value = value > 100 ? 100 : value;
            this.uploadProgress = value;
          } else if(event.type === HttpEventType.Response) {
            // done
            const res =<HttpResponse<IResultResp>>event;
            this.loading = false;
            if(res.body?.result) {
              console.log('res.body.result=%o', res.body?.result);

              if (res.body?.ok) {
                this.info_msg = this.appComponent.translate.instant(res.body.result);

                this.reloadData();
              } else {
                this.error_msg = this.appComponent.translate.instant(res.body.result);
              }

              // clear file
              this.f_File['gifts_file']?.reset();
              this.fileGift =null;
            }
          }
        }, error: err => {
          this.loading = false;
          this.error_msg = this.appComponent.translate.instant('error.file_upload_failed');

          // clear file
          this.f_File['gifts_file']?.reset();
          this.fileGift =null;

          console.log('err=%o', err);
        }
      });
  }

  reset() {
    this.uploadProgress =0;
  }

}

