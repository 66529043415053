import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {AppComponent} from "../app.component";
import {Router} from "@angular/router";
import {finalize, first, map, Subscription} from "rxjs";
import {TranslateModule} from "@ngx-translate/core";
import {NgForOf, NgIf} from "@angular/common";
import {environment} from "../../environments/environment";
import {HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";
import {IResultResp, User, IPoint} from "../../models";
import {globals} from "../../conf/globals";
import {StringReplacePipe} from "../../helpers/string-replace.pipe";
import {compareFunc} from "../../helpers/compareFunc";
import {captureFileExt} from "../../helpers/extractPattern";
import {LocalDateformatPipe} from "../../helpers/local-dateformat.pipe";
import {MatInputModule} from "@angular/material/input";
import {MatRadioModule} from "@angular/material/radio";
import {AddPointsUploadInvComponent} from "../add-points-uploadinv/add-points-upload-inv.component";
import {RemoveAngleCharsPipe} from "../../helpers/RemoveAngleCharsPipe";
import {clearAllFormControlsErrors} from "../../helpers/tools";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'app-add-points',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    NgIf,
    StringReplacePipe,
    NgForOf,
    FormsModule,
    LocalDateformatPipe,
    MatInputModule,
    MatRadioModule,
    AddPointsUploadInvComponent,
    RemoveAngleCharsPipe,
    MatIconModule,
  ],
  templateUrl: './add-points.component.html',
  styleUrl: './add-points.component.css'
})
export class AddPointsComponent implements OnInit, OnDestroy {
  error_msg = '';
  info_msg = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  // brand: string|string[] ='';  // chosen brand
  pointsRecs: Partial<IPoint>[] =[];
  pointsRecsBackup: Partial<IPoint>[] =[];

  user: User = this.appComponent.userService.currentUserValue;

  allSubscriptions: Subscription[] = [];

  showRemarks: any = {}; // object with key=idx, value=true means show remarks of the rec at idx
  showDispute: any ={}; // object with key=idx, value=true means show dispute box for user to input

  // for recFilter
  recFilterOptions: any[] = [];

  // for brandFilter
  brandFilterOptions: any[] = [];

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private router: Router,
              private http: HttpClient
  ) {
    // console.log('>>> ChangePasswordComponent.constructor(): pathname=%s, a=%s',
    //   route.snapshot.url[0].path, route.snapshot.url[0].parameters['a']);

    // formArray for each row
    this.form = this.formBuilder.group({
      recFilter: [],
      brandFilter: [],
      points_rec_id: [''],
      aliases: this.formBuilder.array([]),
    });

    this.allSubscriptions.push(appComponent.userService.currentUser.subscribe(u => {
      // console.log('brand=%s', u?.brand);
      this.user = u;

      // this.initBrandFilter();

    }));

  }

  get f() {return this.form.controls;}

  get aliases() { return this.form.get('aliases') as FormArray }

  onChangeRecFilter(event: any) {
    const filterOption = event.target.value;
    console.log('>>> changeRecFilter: %s', filterOption);

    this.reloadData();
  }

  onChangeBrandFilter(event: any) {
    const filterOption = event.target.value;
    console.log('>>> changeBrandFilter: %s', filterOption);

    this.reloadData();
  }

  onChangePointsRecIdFilter() {
    const rec_id = this.f['points_rec_id'].value.trim()
    // console.log('onChangePointsRecId: id=%s', rec_id)

    // update display
    if(rec_id.length === 0) {
      this.pointsRecs = this.pointsRecsBackup
    } else {
      this.pointsRecs = this.pointsRecsBackup.filter(rec => {
        return rec.id === rec_id
      })
    }
  }

  ngOnInit(): void {
    ///////////////////////
    // recFilter options
    this.recFilterOptions = [];

    if(this.user.hasRole('ROLE_ADMIN')) {
      this.recFilterOptions.push({val:'new', display: this.appComponent.translate.instant('select.new_invoices')});
    }

    const currentYear = Number(LocalDateformatPipe.instant(new Date(), 'YYYY'));
    for(let i=0; i < 7; i++ ) {
      this.recFilterOptions.push({ val: (currentYear -i).toString(),
        display: this.appComponent.translate.instant('select.year') +' ' +(currentYear -i).toString()} );
    }

    let defaultRecFilter =currentYear.toString();
    if(this.user.hasRole('ROLE_ADMIN')) {
      defaultRecFilter ='new';
    }
    this.f['recFilter'].setValue(defaultRecFilter);

    this.initBrandFilter();
  }

  initBrandFilter() {
    ////////////////////////
    // brandFilter
    this.brandFilterOptions =[ {val:'*', display: this.appComponent.translate.instant('select.all_brands') } ];
    const userBrand = this.appComponent.user.brand;
    let defaultBrand ='*';
    if(typeof userBrand === 'object' && userBrand.length > 0) {
      // provide breakdown list
      for(let brand of userBrand) {
        this.brandFilterOptions.push({val: brand, display: brand});
      }
    }
    this.f['brandFilter'].setValue(defaultBrand);

    this.reloadData();
  }

  ngOnDestroy(): void {
    while(this.allSubscriptions.length) {
      this.allSubscriptions.pop()?.unsubscribe();
    }
  }

  // handle events from child components
  onUserEvent(event:any) {
    event = event ?? {};
    Object.entries(event).map( ([key, value]) => {
      switch(key) {
        case 'info_msg':
          // @ts-ignore
          this.info_msg = value;
          break;

        case 'error_msg':
          // @ts-ignore
          this.error_msg = value;
          break;

        case 'reloadData':
          this.reloadData();
          break;
      }
    }, this)
  }

  reloadData() {
    this.showRemarks ={}
    this.showDispute = {}

    // conditions? : [{ var: 'isNew', op: '=', value: true}, { var: 'createdOn', op: '>', value: '2023-12-23T12:34:56+08:00', type: 'Date' } ]
    let conditions =[];

    const filterSelection =this.f['recFilter'].value;
    if(filterSelection === 'new') {
      conditions.push({var: 'added_by', op: '=', value: null});
    } else {
      // filter record of the selected year
      conditions.push({var: 'createdOn', op: '>=', value: `${filterSelection}-01-01T00:00:00${globals.date_str_tz_offset_suffix}`,
        type: 'Date'});
      conditions.push({var: 'createdOn', op: '<', value: `${Number(filterSelection)+1}-01-01T00:00:00${globals.date_str_tz_offset_suffix}`,
        type: 'Date'});
    }

    // set brands
    let brand = this.f['brandFilter'].value;

    // console.log('reloadData 1: brand=%s', brand);

    if(brand === '*') {
      // conditions.push({var: 'brand', op: 'IN', value: this.appComponent.user.brand});
      brand =this.appComponent.user.brand;
    }

    // console.log('reloadData 2: brand=%s', brand);

    // else {
    //   conditions.push({var: 'brand', op: '=', value: brand});
    // }

    // console.log('>>> reloadData:conditions= %o, typeof value=%s', conditions, typeof conditions[0]?.value);

    const needUserFullName = this.user.hasRole('ROLE_ADMIN')

    this.appComponent.rewardService.getAllPointsRec(brand, conditions, needUserFullName)
      .pipe(first()).subscribe({
      next: (res) => {
        // done
        if(res.ok) {
          this.pointsRecs =res.points;

          // clear form array
          this.aliases.controls =[];

          this.pointsRecs = res.points.sort(
            (b, a) => {
              if (a.createdOn && b.createdOn) return compareFunc(a.createdOn, b.createdOn);
              return -1;
            });

          for(let i=0; i < this.pointsRecs.length; i++) {
            const aliasForm = this.formBuilder.group({
              'points': [this.pointsRecs[i].points?.toString(),
                Validators.compose([ Validators.required, Validators.pattern(/^\d+$/)])
              ],
              'remarks': [this.pointsRecs[i].remarks],
              'dispute_reason': [],
            })

            this.aliases.push(aliasForm);

            // console.log('alias #%s: remarks=%s', i, this.aliases.at(i).get('remarks')?.value);
          }

          // make a copy
          this.pointsRecsBackup = [ ...this.pointsRecs]
          if(this.f['points_rec_id'].value) this.onChangePointsRecIdFilter()

          // console.log('>>> got pointsRecs = %o', this.pointsRecs);
        } else {
          this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
        }
      }, error: err => {
        this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
      }
    });

  }

  onClickInvoice(idx : number) {
    const path =this.pointsRecs[idx]?.['invoices'] ?? '';
    console.log('show invoice: %s', path);

    // const headers =new HttpHeaders().append('Accept', "application/pdf");
    // console.log('headers=%o', headers)

    const queryParams =new HttpParams().append('path', path);

    console.log('params=%o', queryParams)

    const options ={
      withCredentials: true,
      responseType: 'blob' as 'json',
      // headers: headers,
      params: queryParams
    }

    let contentType: string;
    switch(captureFileExt(path).toLowerCase()) {
      case '.pdf':
        contentType = 'application/pdf';
        break;

      case '.csv':
      default:
        contentType = 'application/octet-stream';
        break;
    }

    return this.http.get(environment.api_url +'/getPdfInvoice', options)
      .pipe(map<any, Blob>( res => res))
      .subscribe({
        next: (blob: Blob) => {
          const file = new Blob([blob], {type: contentType});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, 'nrg');
        }
      });
  }
  onClickRemark(idx : string) {
    const orgValue =this.showRemarks[idx]
    if(!orgValue) this.showRemarks ={}
    this.showRemarks[idx] = !orgValue

    clearAllFormControlsErrors(this.aliases.at(Number(idx)))
  }

  onClickWriteDispute(idx: number) {
    const orgVal = this.showDispute[idx]
    if(!orgVal) this.showDispute = {}
    this.showDispute[idx] =!orgVal

    clearAllFormControlsErrors(this.aliases.at(Number(idx)))
  }

  onClickUpdatePoints(idx : number) {
    this.submitted = true;

    console.log('onClickUpdatePoints: %s: %s, %s, error=%o', idx,
      this.aliases.at(idx).get('points')?.value, this.aliases.at(idx).get('remarks')?.value,
      this.aliases.at(idx).invalid);

    this.info_msg = this.error_msg = '';

    if(this.aliases.at(idx).invalid) return;

    this.loading = true;

    this.appComponent.rewardService.updatePoints(this.pointsRecs[idx].id,
      this.aliases.at(idx)?.get('points')?.value, this.aliases.at(idx)?.get('remarks')?.value)
      .pipe().subscribe({
      next: res => {
        this.loading = false;
        if(res.result) {
          if (res.ok) {
            this.info_msg = this.appComponent.translate.instant(res.result);
            this.reloadData();
          } else {
            this.error_msg = this.appComponent.translate.instant(res.result);
          }
        }

      }, error: err => {
        this.loading = false;
        this.error_msg = this.appComponent.translate.instant('db.error.exception');
      }
    });

  }

  onClickUpdateDispute(idx: number) {
    const dispute_reason =this.aliases.at(idx).get('dispute_reason')?.value
    console.log(">> onClickUpdateDispute(%s): reason='%s'",
      idx, dispute_reason)

    this.info_msg = this.error_msg = '';
    this.submitted =true

    this.aliases.at(idx).get('dispute_reason')?.setErrors(null)
    if(dispute_reason === null || dispute_reason.replace(/\s/g, '').length === 0) {
      this.aliases.at(idx).get('dispute_reason')?.setErrors({required: true})

      // console.log('### errors:%o', this.aliases.at(idx).get('dispute_reason')?.['errors']?.['required'])
    }

    if(this.aliases.at(idx).invalid) return;

    //debug
    // console.log('>>> onClickUpdateDispute: passed')
    // return

    this.loading = true;

    const dispute_api = this.user.hasRole('ROLE_ADMIN') ?
      this.appComponent.rewardService.settleDisputeInvoice : this.appComponent.rewardService.disputeInvoice;

    dispute_api.call(this.appComponent.rewardService, this.pointsRecs[idx].id,
      this.aliases.at(idx)?.get('dispute_reason')?.value)
      .pipe().subscribe({
      next: res => {
        this.loading = false;
        if(res.result) {
          if (res.ok) {
            this.info_msg = this.appComponent.translate.instant(res.result);
            this.reloadData();
          } else {
            this.error_msg = this.appComponent.translate.instant(res.result);
          }
        }
      }, error: err => {
        this.loading = false;
        this.error_msg = this.appComponent.translate.instant('db.error.exception');
      }
    });

  }


  protected readonly _globals = globals;
  protected readonly FormControl = FormControl;
}
