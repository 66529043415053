import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeAngleChars',
  standalone: true
})
export class RemoveAngleCharsPipe implements PipeTransform {
  transform(s: string|undefined): string {
    return RemoveAngleCharsPipe.instant(s);
  }

  static instant(s: string|undefined): string {
    s = s ?? '';
    return s.replace(/&/g, "&amp;") // "&" must be handled first
      .replace(/\</g, '&lt;')
      .replace(/\>/g, '&gt;')
      ;
  }
}
