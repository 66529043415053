<form [formGroup]="regUserForm" class="form-signin text-center" (ngSubmit)="onFinalSubmit()"  >

  <h2 class="form-signin-heading">{{ 'page.registration' | translate}}</h2>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert"
    [innerHTML]="error_msg"></div>

  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg"></div>

  <div class="text-start" style="display: inline-block">
    <div class="form-field">
      <mat-form-field class="form-field-full">
        <mat-label class="sr-only" [innerHTML]="'caption.email' | translate"></mat-label>
        <input matInput type="text" id="email" formControlName="email"
               placeholder="{{ 'caption.email' | translate }}"
               maxlength="{{ _globals.maxFieldLength }}"
               autofocus
        >
        <mat-icon *ngIf="submitted && f['email']['errors']" matIconSuffix>error</mat-icon>

        @if(submitted && f['email']['errors']){
          <mat-error *ngIf="f['email']['errors']['required']">{{ 'error.email_is_reqd' | translate }}</mat-error>
          <mat-error *ngIf="f['email']['errors']['email']">{{ 'error.invalid_email_format' | translate }}</mat-error>
        }
      </mat-form-field>
    </div>

    <div class="form-field">
      <mat-form-field class="form-field-full">
        <mat-label class="sr-only" [innerHTML]="'caption.fullname' | translate"></mat-label>
        <input matInput type="text" id="fullName" formControlName="fullName"
               placeholder="{{ 'caption.fullname' | translate }}"
               minlength="{{ _globals.minFieldNameLength }}"
               maxlength="{{ _globals.maxFieldLength }}"
        >
        <mat-icon *ngIf="submitted && f['fullName']['errors']" matIconSuffix>error</mat-icon>

        @if(submitted && f['fullName']['errors']){
          <mat-error *ngIf="f['fullName']['errors']['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
          <mat-error *ngIf="f['fullName']['errors']['minlength']">{{ 'error.field_min_len' | translate: {n: f['fullName']['errors']['minlength']['requiredLength']} }}</mat-error>
        }
      </mat-form-field>
    </div>

    <div class="form-field">
      <mat-form-field class="form-field-full">
        <mat-label class="sr-only" [innerHTML]="'caption.mobile' | translate"></mat-label>
        <input matInput type="text" id="mobile" formControlName="mobile"
               placeholder="{{ 'caption.mobile' | translate }}"
               minlength="{{ _globals.minFieldMobileLength }}"
               maxlength="{{ _globals.maxFieldLength }}"
        >
        <mat-icon *ngIf="submitted && f['mobile']['errors']" matIconSuffix>error</mat-icon>

        @if(submitted && f['mobile']['errors']){
          <mat-error *ngIf="f['mobile']['errors']['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
          <mat-error *ngIf="f['mobile']['errors']['minlength']">{{ 'error.field_min_len' | translate: {n: f['mobile']['errors']['minlength']['requiredLength']} }}</mat-error>
        }
      </mat-form-field>
    </div>

    <div class="form-field">
      <mat-form-field class="form-field-full">
        <mat-label class="sr-only" [innerHTML]="'caption.company' | translate"></mat-label>
        <input matInput type="text" id="company" formControlName="company"
               placeholder="{{ 'caption.company' | translate }}"
               minlength="{{ _globals.minFieldNameLength }}"
               maxlength="{{ _globals.maxFieldLength }}"
        >
        <mat-icon *ngIf="submitted && f['company']['errors']" matIconSuffix>error</mat-icon>

        @if(submitted && f['company']['errors']){
          <mat-error *ngIf="f['company']['errors']['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
          <mat-error *ngIf="f['company']['errors']['minlength']">{{ 'error.field_min_len' | translate: {n: f['company']['errors']['minlength']['requiredLength']} }}</mat-error>
        }
      </mat-form-field>
    </div>
  </div>

  <div class=" text-center w-100">
    <div class="d-inline-block form-field-full w-75">
      <textarea matInput formControlName="tnc" class="mb-1 form-field-full "
                cdkTextareaAutosize
                cdkAutosizeMinRows="10" cdkAutosizeMaxRows="20"
                readonly
      ></textarea>
    </div>

    <div *ngIf="!agreedTnC" class="form-group text-center">
      <button [disabled]="loading" class="btn btn-sm btn-primary btn-block"
              type="button" (click)="onClickAgreeTnC()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ 'caption.agree_tnc' | translate }}
      </button>
    </div>
  </div>

  <div class="text-start d-inline-block">
    <div *ngIf="agreedTnC" class="form-group text-center">
      <button *ngIf="!verificationCodeReceived" [disabled]="loading" class="btn btn-sm btn-primary btn-block"
              type="button" (click)="onGetVerficationCode()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ 'caption.register_new_user' | translate }}
      </button>
    </div>

    <div *ngIf="verificationCodeReceived" class="form-group">
      <div *ngIf="showEnterVerifyCodeMsg" class="alert alert-success"
           [innerHTML]="'alert.verification_code_sent_by_email' | translate">
      </div>

      <div class="form-field-full">
        <mat-form-field class="form-field-full">
          <input matInput type="text" id="verificationCode" formControlName="verificationCode"
                 placeholder="{{ 'caption.verification_code' | translate }}"
                 minlength="{{ _globals.emailVerificationCodeLen }}"
                 maxlength="{{ _globals.emailVerificationCodeLen }}"
                 autofocus
          >
          <mat-icon *ngIf="submitted && f['verificationCode']['errors']" matIconSuffix>error</mat-icon>

          <mat-hint [innerHTML]="'hint.verification_code'|translate" ></mat-hint>
          @if(submitted && f['verificationCode']['errors']){
            <mat-error *ngIf="f['verificationCode']['errors']['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
            <mat-error *ngIf="f['verificationCode']['errors']['minlength']">{{ 'error.field_min_len' | translate: {n: f['verificationCode']['errors']['minlength']['requiredLength']} }}</mat-error>
          }
        </mat-form-field>
      </div>

      <div class="form-group text-center">
        <button [disabled]="loading || f['verificationCode']['errors']" class="btn btn-sm btn-primary btn-block">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ 'caption.submit' | translate }}
        </button>
      </div>
    </div>

  </div>

</form>
