import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {User} from "../../models";
import {first, Subject, Subscription} from "rxjs";
import {AppComponent} from "../app.component";
import {HttpClient} from "@angular/common/http";
import {LocalDateformatPipe} from "../../helpers/local-dateformat.pipe";
import {StringReplacePipe} from "../../helpers/string-replace.pipe";
import {DATE_FORMATS, globals} from "../../conf/globals";
import {compareFunc} from "../../helpers/compareFunc";
import {IRedeemOrder} from "../../models";
import {IGift} from "../../models";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {MatSelectModule} from "@angular/material/select";
import {RemoveAngleCharsPipe} from "../../helpers/RemoveAngleCharsPipe";
import {NumberCommasPipe} from "../../helpers/number_commas.pipe";
import {RedeemAddItemsComponent} from "../redeem-add-items/redeem-add-items.component";


@Component({
  selector: 'app-redeem',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    TranslateModule,
    StringReplacePipe,
    NgForOf,
    FormsModule,
    LocalDateformatPipe,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatSelectModule,
    RemoveAngleCharsPipe,
    NumberCommasPipe,
    RedeemAddItemsComponent
  ],
  providers: [
    { provide: DateAdapter, useClass:MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
  templateUrl: './redeem.component.html',
  styleUrl: './redeem.component.css'
})
export class RedeemComponent implements OnInit, OnDestroy {
  error_msg = '';
  info_msg = '';
  order_error_msg = '';
  form: FormGroup;

  loading = false;
  submitted = false;
  user: User = this.appComponent.userService.currentUserValue;

  allSubscriptions: Subscription[] = [];

  // for recFilter
  recFilterOptions: any[] = [];

  // for vendor brandFilter
  vendorBrandFilterOptions: any[] = [];

  ordersRecs: Partial<IRedeemOrder>[] = [];

  reloadDataSubject = new Subject<Boolean>();
  reloadDataDoneObservable = this.reloadDataSubject.asObservable();

  giftsArray: Partial<IGift>[] =[];

  // set when user Redeem new item, or Edit existing order
  orderAction: 'new'|'edit'|null =null;
  orderToEdit: Partial<IRedeemOrder> = {}

  get f() {return this.form.controls;}
  get aliases() { return this.form.get('aliases') as FormArray }

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private http: HttpClient,
  ) {
    // console.log('>>> ChangePasswordComponent.constructor(): pathname=%s, a=%s',
    //   route.snapshot.url[0].path, route.snapshot.url[0].parameters['a']);

    // formArray for each row
    this.form = this.formBuilder.group({
      'recFilter': [],
      'brandFilter': [],
      'orderStatus': [],
      'remarks': [],
      'itemName': [],
      'itemQty': [],
      'aliases': this.formBuilder.array([]),
    });

    this.allSubscriptions.push(appComponent.userService.currentUser.subscribe(u => {
      this.user = u;
      // this.initBrandFilter();
      // this.initGiftsOptions();
    }));

  }

  changeRecFilter(event: any) {
    const filterOption = event.target.value;
    console.log('>>> changeRecFilter: %s', filterOption);

    this.reloadData();
  }

  changeBrandFilter(event: any) {
    const filterOption = event.target.value;
    console.log('>>> changeBrandFilter: %s', filterOption);

    this.reloadData();
  }

  // open modal form
  onEditGiftOrder(orderNum? : string) {
    console.log('>>> onEditGiftOrder(%s)', orderNum);

    const order = this.findOrder(orderNum);
    if(order) {
      this.orderAction = 'edit';
      this.orderToEdit = order
    }
  }

  findOrder(orderNum: string|undefined) : Partial<IRedeemOrder>|undefined {
    if(!orderNum) return undefined;
    return this.ordersRecs.find( order => order.orderNum === orderNum);
  }

  ngOnInit(): void {
    ///////////////////////
    // recFilter options
    this.recFilterOptions = [];

    if(this.user.hasRole('ROLE_ADMIN')) {
      this.recFilterOptions.push({val:'new', display: this.appComponent.translate.instant('select.new_orders')});
    }

    const currentYear = Number(LocalDateformatPipe.instant(new Date(), 'YYYY'));
    for(let i=0; i < 7; i++ ) {
      this.recFilterOptions.push({ val: (currentYear -i).toString(),
        display: this.appComponent.translate.instant('select.year') +' ' +(currentYear -i).toString()} );
    }

    let defaultRecFilter =currentYear.toString();
    if(this.user.hasRole('ROLE_ADMIN')) {
      defaultRecFilter ='new';
    }
    this.f['recFilter'].setValue(defaultRecFilter);

    this.initBrandFilter();
    this.initGiftsArray();
  }

  initBrandFilter() {
    ////////////////////////
    // brandFilter
    this.vendorBrandFilterOptions =[ {val:'*', display: this.appComponent.translate.instant('select.all_brands') } ];
    let userBrand = this.appComponent.user.brand;

    // console.log('userBrand=%s', userBrand)

    if(userBrand && !Array.isArray(userBrand)) {
      // turn it into brand
      userBrand = [userBrand]
    }

    let defaultBrand ='*';
    if(typeof userBrand === 'object' && userBrand.length > 0) {
      // provide breakdown list
      for(let brand of userBrand) {
        this.vendorBrandFilterOptions.push({val: brand, display: brand});
      }
    }
    this.f['brandFilter'].setValue(defaultBrand);

    this.reloadData();
  }

  initGiftsArray() {
    // get gifts db for itemsOptions
    const brand = this.user.brand;

    if(!this.user.hasRole('ROLE_ADMIN')) {
      // gift list is only needed for user
      this.appComponent.rewardService.getAllGiftsRec(brand)
        .pipe(first()).subscribe({
        next: (res) => {
          // done
          if (res.ok) {
            // console.log('>>> getAllGiftsRec res = %o', res);

            this.giftsArray = res.gifts
            // .sort(
            // (a, b) => {
            //   if (a.brand && b.brand) return compareFunc(a.brand+':'+a.name, b.brand+':'+b.name);
            //   return -1;
            // });

            // display new order child
            if (this.orderAction === null) this.orderAction = 'new';
          } else {
            this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
          }
        }, error: err => {
          this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
        }
      });
    }

  }

  ngOnDestroy(): void {
    while(this.allSubscriptions.length) {
      this.allSubscriptions.pop()?.unsubscribe();
    }
  }

  // reloadData: true
  // reloadOrder: <order num>
  // closeOrderDisp: true
  onChildEvent(events: any) {
    console.log('>>> onChildEvent: event=%o', events)

    for(let key in events) {
      switch (key) {
        case 'reloadData':
          this.reloadData()
          break

        case 'reloadOrder':
          this.reloadDataDoneObservable.pipe(first()).subscribe(value => {
            if (value) {
              console.log('>>> reloadData done: value=%s', value);

              const orderNum = events[key];
              this.onEditGiftOrder(orderNum);
            }
          })
          break

        case 'closeOrderDisp':
          this.orderAction =null
          this.orderToEdit ={}
          break
      }
    }
  }

  reloadData() {
    console.log('>>> reloadData()')

    // conditions? : [{ var: 'isNew', op: '=', value: true}, { var: 'createdOn', op: '>', value: '2023-12-23T12:34:56+08:00', type: 'Date' } ]
    let conditions =[];

    const filterSelection =this.f['recFilter'].value;
    if(filterSelection === 'new') {
      conditions.push({var: 'status', op: '=', value: 'new'});
    } else {
      // filter record of the selected year
      conditions.push({var: 'createdOn', op: '>=', value: `${filterSelection}-01-01T00:00:00${globals.date_str_tz_offset_suffix}`,
        type: 'Date'});
      conditions.push({var: 'createdOn', op: '<', value: `${Number(filterSelection)+1}-01-01T00:00:00${globals.date_str_tz_offset_suffix}`,
        type: 'Date'});
    }

    // set brands
    let brand = this.f['brandFilter'].value;

    if(brand === '*') {
      // conditions.push({var: 'brand', op: 'IN', value: this.appComponent.user.brand});
      brand =this.appComponent.user.brand;
    }
    // console.log('>>> reloadData:conditions= %o, typeof value=%s', conditions, typeof conditions[0]?.value);

    this.appComponent.rewardService.getAllRedeemOrdersRec(brand, conditions)
      .pipe(first()).subscribe({
      next: (res) => {
        // done
        if(res.ok) {
          // console.log('>>> res = %o', res);

          this.ordersRecs = res.orders.sort(
            (b, a) => {
              if (a.createdOn && b.createdOn) return compareFunc(a.createdOn, b.createdOn);
              return -1;
            });

          // sort items by createdOn
          this.ordersRecs.map( orderRec => {
            orderRec.items = orderRec.items?.sort(
              (a,b) => {
                if (a.createdOn && b.createdOn) return compareFunc(a.createdOn, b.createdOn);
                return -1;
              })
            });

          // console.log('>>> got ordersRecs = %o', this.ordersRecs);
          this.reloadDataSubject.next(true)
        } else {
          this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
          this.reloadDataSubject.next(false)
        }

      }, error: err => {
        this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
        this.reloadDataSubject.next(false)
      }
    });

  }

  protected readonly _globals = globals;
}
