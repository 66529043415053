import { Component } from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {AppComponent} from "../app.component";
import {Router} from "@angular/router";
import {globals} from "../../conf/globals";
import {first} from "rxjs";
import {compareFunc} from "../../helpers/compareFunc";

@Component({
  selector: 'app-forgot-password',
  standalone: true,
    imports: [
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        NgIf,
        ReactiveFormsModule,
        TranslateModule
    ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css'
})
export class ForgotPasswordComponent {
  protected readonly _globals = globals;

  error_msg='';
  info_msg = '';
  form: FormGroup;
  loading = false;
  submitted = false;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private router: Router
  ) {

    // console.log('>>> LoginComponent.constructor()');

    this.form = this.formBuilder.group({
      'email': ['', Validators.compose(
        [ Validators.required, Validators.email, Validators.maxLength(globals.maxFieldLength)]
      )],
    });
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted =true;

    this.info_msg = this.error_msg = '';

    if(this.form.invalid) return;

    const email =this.f['email']?.value;
    this.appComponent.userService.forgotPassword(email)
      .pipe(first()).subscribe({
      next: (res) => {
        // done
        if(res.ok) {
          // console.log('>>> res = %o', res);
          this.info_msg = this.appComponent.translate.instant(res.result);
          this.form.reset();
          this.submitted = false;
        } else {
          this.error_msg = this.appComponent.translate.instant(res.result);
        }

        this.loading = false;
      }, error: err => {
        this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
        this.loading = false;
      }
    });

  }

}
