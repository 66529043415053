import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringReplace',
  standalone: true
})
export class StringReplacePipe implements PipeTransform {

  transform(value: string|undefined, toReplace:string, replaceBy: string): string|undefined {
    return value ? value.replace(new RegExp(toReplace, 'g'), replaceBy) : value;
  }

}
