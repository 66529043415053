import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {globals} from "../conf/globals";

// return: null = passed
export function ValidatePasswordSpecialChars(allowEmpty?:boolean) : ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // console.log('password.name=%s', control instanceof FormGroup)

    const pw =control.get('password'), cpw = control.get('confirm_password');

    if(allowEmpty) {
      if (!pw?.value) {
        pw?.setErrors(null);

        if(!cpw?.value) cpw?.setErrors(null);

        return null;
      }

      if(pw?.value.length < globals.minPasswordLength) {
        pw?.setErrors({'minlength': {requiredLength: globals.minPasswordLength } });
        return null;
      }
    }

    if(!/[!@#$%]/.test(pw?.value)) {
      // special char "!@#$%" missing
      pw?.setErrors({ specialChars: {value: pw?.value} });
      return null;
    }

    if(!/\d/.test(pw?.value)) {
      // digit exists?
      pw?.setErrors({ noDigit: {value: pw?.value} });
      return null;
    }

    if(!/.*(?=.*[a-z])(?=.*[A-Z]).*/.test(pw?.value)) {
      // both small and capital letters exist?
      pw?.setErrors({ small_n_capital_letters: {value: pw?.value} });
      return null;
    }

    // clear error
    pw?.setErrors(null);
    return null;
  }
}

// validator for form
export function ValidateConfirmPassword(allowEmpty?:boolean) : ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const pw = control.get('password'), cpw = control.get('confirm_password');
    // console.log('ValidateConfirmPassword: "%s" "%s"', pw, cpw);

    if(allowEmpty) {
      if(!pw?.value && !cpw?.value) {
        // clear errors
        pw?.setErrors(null);
        return null;
      }

      if(!cpw?.value) {
        control.get('confirm_password')?.setErrors( {'required': {value: true} } );
        return {'required': {value: true} }
      }
    }

    if (pw?.value !== cpw?.value) {
      // failed
      return {unmatched_password: {value: control.value}};
    }

    // passed
    return null;
  }
}
