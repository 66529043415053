import { Component } from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {AppComponent} from "../app.component";
import {first} from "rxjs";
import {NumberCommasPipe} from "../../helpers/number_commas.pipe";

@Component({
  selector: 'app-welcome',
  standalone: true,
    imports: [
        TranslateModule,
        AppComponent,
        NumberCommasPipe
    ],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.css'
})
export class WelcomeComponent {

  constructor(private appComponent:AppComponent) {
  }

  user = this.appComponent.userService.currentUserValue;

  // onClickTest() {
  //   console.log('test clicked');
  //   this.appComponent.userService.sendVerificationCodeEmail()
  //     .pipe(first()).subscribe(data => {
  //       console.log('## %o', data);
  //   });
  // }
}
