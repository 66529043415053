import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule, ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {AppComponent} from "../app.component";
import {Router} from "@angular/router";
import {HttpClient, HttpEventType, HttpResponse} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {finalize, first, Subscription} from "rxjs";
import {IResultResp, User} from "../../models";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import {DATE_FORMATS, globals} from "../../conf/globals";
import {LocalDateformatPipe} from "../../helpers/local-dateformat.pipe";
import {MatSelectModule} from "@angular/material/select";
import {RemoveAngleCharsPipe} from "../../helpers/RemoveAngleCharsPipe";
import {MatIconModule} from "@angular/material/icon";
import {IDialogPromptData} from "../../models/DialogPromptData";
import {MatDialog} from "@angular/material/dialog";
import {DialogPromptOkComponent} from "../dialog-prompt-ok/dialog-prompt-ok.component";
import {clearAllFormControlsErrors} from "../../helpers/tools";

@Component({
  selector: 'app-add-points-uploadinv',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgForOf,
    MatSelectModule,
    MatIconModule
  ],
  providers: [
    { provide: DateAdapter, useClass:MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
  templateUrl: './add-points-upload-inv.component.html',
  styleUrl: './add-points-upload-inv.component.css'
})
export class AddPointsUploadInvComponent implements OnInit, OnDestroy {
  @Input() user!: User;
  @Output() userEvent = new EventEmitter<any>();

  form: FormGroup;

  fileInvoice: any = null;
  fileSize =0;
  uploadProgress =0;
  loading = false;
  submitted = false;

  userAction : 'upload'|'meetingDate'|'transferPoints' = 'upload'

  meetingTimeOptions :any[] =[];
  salesmanOptions: any[] = [];
  subscription =new Subscription()

  dialogPromptData: Partial<IDialogPromptData> = {}

  get f() {return this.form.controls;}

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private router: Router,
              private http: HttpClient,
              public dialog: MatDialog) {

    this.form = this.formBuilder.group({
      invoice_file: [''],
      invoice_number: [''],
      userAction: [ this.userAction ],
      meetingDate: [],
      meetingTime: [],
      salesman: [],
      targetEmail: []
    });

    this.subscription.add(this.f['userAction'].valueChanges.subscribe((v) => {
      // console.log('userAction=%o', v)
      this.userAction = v
      this.setValidators()
    }))

    this.setValidators()
  }

  setValidators() {
    this.userEvent.emit({
      info_msg: '',
      error_msg: ''
    })

    switch(this.userAction) {
      case 'meetingDate':
        this.form.setValidators([this.meetingInputsValidator()])
        this.f['invoice_file'].clearValidators();
        this.f['invoice_number'].clearValidators();
        break;

      case 'upload':
        // upload invoice file
        this.form.clearValidators()
        this.f['invoice_file'].setValue('')
        this.f['invoice_file'].setValidators([Validators.required] )
        this.f['invoice_number'].setValidators([Validators.required] )
        break;

      case 'transferPoints':
        this.form.setValidators([this.transferPointsValidator()])
        this.f['invoice_file'].clearValidators();
        this.f['invoice_number'].clearValidators();
        break;
    }

    clearAllFormControlsErrors(this.form)
    this.form.updateValueAndValidity()

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  ngOnInit() {
    for(let t=9; t < 20; t++) {
      let val = t.toString().padStart(2, '0') + ':00'
      this.meetingTimeOptions.push({val: val, display: val})
    }

    // get sales list
    this.appComponent.systemService.getSetting(`brand_${this.user.brand}_email_req_meeting_sales_list`)
      .pipe(first()).subscribe({
      next: (res) => {
        let emails_list = res.result ?? false;

        if(emails_list) {
          emails_list.split(',').map( email => {
            email = email.trim()

            if(email) {
              this.salesmanOptions.push({
                val: email,
                display: RemoveAngleCharsPipe.instant(email)
              })
            }
          })

          // console.log('>>> salesmanOptions=%o', this.salesmanOptions)
        }
      }
    })

  }

  // upload invoice
  onUploadInvoice() {
    this.submitted = true;

    this.userEvent.emit({
      info_msg: '',
      error_msg: ''
    })

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    // check if the invoice number is duplicated
    this.http.post<any>(environment.api_url +'/checkDupInvoice',
      {invoice_number: this.f['invoice_number']?.value },
      {withCredentials: true })
      .pipe(first())
      .subscribe({
        next: (resp:IResultResp) => {
          // console.log('>>> checkDupInvoice(): resp=%o', resp);

          if(resp.ok) {
            //debug
            // console.log('>>> checkDupInvoice: passed')
            // this.loading = false

            this.uploadInvoice()
          } else {
            // invoice number NG, prompt user to continue?
            this.loading = false;

            this.dialogPromptData = {
              title: this.appComponent.translate.instant('caption.invoice_number_problem'),
              msg: this.appComponent.translate.instant(resp.result) +'<br>' +this.appComponent.translate.instant('caption.continue?'),
              input_ans: '',
              yes_button: this.appComponent.translate.instant('caption.yes'),
              no_button: this.appComponent.translate.instant('caption.no'),
              result_yes: 'y',
              result_no: 'n',
            }

            // console.log('>>> data=%o', this.dialogPromptData)

            const dialogRef =this.dialog.open(DialogPromptOkComponent, {data: this.dialogPromptData} )
            dialogRef.afterClosed().pipe(first()).subscribe(
              res => {
                // console.log('>>> dialogRef: result=%s', res)

                switch(res) {
                case 'y':
                  this.uploadInvoice()
                  break
                case 'n':
                  // user gives up upload invoice
                  this.userEvent.emit({
                    error_msg: this.appComponent.translate.instant(resp.result),
                  })
                  break;
                }
              }
            )

          }
        }, error: err => {
          this.loading = false;

          this.userEvent.emit({
            error_msg: this.appComponent.translate.instant('error.failed_to_check_dup_invoice'),
          })
        }
      });
  }

  uploadInvoice() {
    this.userEvent.emit({
      info_msg: '',
      error_msg: ''
    })

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    // console.log(">>> onUpload:")

    const fileSize = (this.fileInvoice as File).size;

    // create formData to upload file
    const formData = new FormData();
    formData.append('invoice_file', this.fileInvoice);
    formData.append('invoice_number', this.f['invoice_number'].value);

    this.http.post<any>(environment.api_url +'/uploadInvoice',
      formData, {withCredentials: true, reportProgress: true, observe: 'events'})
      .pipe(finalize(() => this.reset()))
      .subscribe({
        next: (event) => {
          // console.log('>>> event.type=%s, %o', event.type, event);

          if(event.type === HttpEventType.UploadProgress ) {
            let value = Math.round( 100 * event.loaded / (event.total ?? fileSize));
            value = value > 100 ? 100 : value;
            this.uploadProgress = value;
          } else if(event.type === HttpEventType.Response) {
            // done
            const res =<HttpResponse<IResultResp>>event;
            this.loading = false;
            if(res.body?.result) {
              if (res.body?.ok) {
                this.userEvent.emit({
                  info_msg: this.appComponent.translate.instant(res.body.result),
                  reloadData: true
                })

              } else {
                this.userEvent.emit({
                  error_msg: this.appComponent.translate.instant(res.body.result),
                })
              }

              // clear file
              this.f['invoice_file']?.reset();
              this.fileInvoice =null;
            }
          }
        }, error: err => {
          this.loading = false;

          this.userEvent.emit({
            error_msg: this.appComponent.translate.instant('error.file_upload_failed'),
          })
        }
      });
  }

  onSelectFile(event: Event) {
    try {
      this.fileInvoice = (event.target as HTMLInputElement)?.files?.[0];
      // console.log('onSelectFile: file = %o', this.fileInvoice);

      this.fileSize =(this.fileInvoice as File).size;
    }catch (e) {console.error(e)}
  }

  reset() {
    this.uploadProgress =0;
  }

  getChosenDateNumber() {
    if(this.f['meetingDate'].invalid || this.f['meetingTime'].invalid) return 0;

    return Date.parse(LocalDateformatPipe.instant(this.f['meetingDate'].value, 'YYYY-MM-DD') +'T' +this.f['meetingTime'].value +':00' +globals.date_str_tz_offset_suffix);
  }

  meetingInputsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      // clear the previous errors
      this.form.markAllAsTouched();
      this.form.setErrors(null);

      if(!this.f['meetingDate'].value) {
        // this.f['meetingDate'].markAsTouched({ onlySelf:true});
        this.f['meetingDate'].setErrors({'meeting_date_not_set': { value: true }});
        return null;
      }

      if(!this.f['meetingTime'].value) {
        // this.f['meetingTime'].markAsTouched({ onlySelf:true});
        this.f['meetingTime'].setErrors({'meeting_time_not_set': { value: true }});
        return null;
      }

      // check if the meeting time will be less than 1 hour left
      let now_str = LocalDateformatPipe.instant(new Date(Date.now() + 60*60*1000), 'YYYY-MM-DD HH:mm')

      const chosenDateStr = LocalDateformatPipe.instant(new Date(this.getChosenDateNumber()), 'YYYY-MM-DD HH:mm')

      // console.log('now_str=%s, chosenDateStr=%s', now_str, chosenDateStr)

      if(chosenDateStr < now_str) {
        this.f['meetingTime'].setErrors({'before_now': { value: true }});
        return null;
      }

      if(!this.f['salesman'].value) {
        // this.f['salesman'].markAsTouched({ onlySelf:true});
        this.f['salesman'].setErrors({'required': { value: true }});
        return null;
      }

      // console.log('meetingInputsValidator 2: form.invalid=%s', this.form.invalid)

      return null;
    }
  }

  transferPointsValidator() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // clear the previous errors
      this.form.markAllAsTouched();
      this.form.setErrors(null);

      const targetEmail = this.f['targetEmail'].value;

      if(!targetEmail) {
        this.f['targetEmail'].setErrors({'required': { value: true }});
        return null;
      }

      const re =/^[^@]+@([^\.]+\..+)$/;
      const matches = targetEmail.toLowerCase().match(re)

      if(!matches || matches.length < 2) {
        this.f['targetEmail'].setErrors({'email': { value: true }});
        return null;
      }

      // check email domain
      const userDomainMatches =this.user?.email?.toLowerCase()?.match(re);
      if(userDomainMatches && userDomainMatches[1] !== matches[1]) {
        this.f['targetEmail'].setErrors({'diff_domain': { value: true }});
        return null;
      }

      return null;
    }
  }

  showErrors() {
    Object.keys(this.form.controls).forEach(key => {
      const errors =this.form.get(key)?.errors;
      if(errors != null) {
        Object.keys(errors).forEach(keyError => {
          console.log('>>> error: %s: %s=%s', key, keyError, errors[keyError]);
        })
      }
    })
  }

  // clearAllErrors() {
  //   Object.keys(this.form.controls).forEach(key => {
  //     const errors =this.form.get(key)?.errors;
  //     if(errors != null) {
  //       Object.keys(errors).forEach(keyError => {
  //         this.f[key].setErrors(null)
  //       })
  //     }
  //   })
  // }

  onRequestMeeting() {
    this.submitted = true;

    this.showErrors()
    // console.log('onRequestMeeting: form.invalid=%s:', this.form.invalid)

    if (this.form.invalid) return;

    this.loading =true;

    this.userEvent.emit({
      info_msg: '',
      error_msg: ''
    })

    this.appComponent.rewardService.requestMeeting(
      new Date(this.getChosenDateNumber()), this.f['salesman'].value)
      .pipe(first()).subscribe({
      next: res => {
        this.loading = false;

        if (res.result) {
          if (res.ok) {
            this.userEvent.emit({
              info_msg: this.appComponent.translate.instant(res.result),
              reloadData: true
            })

          } else {
            this.userEvent.emit({
              error_msg: this.appComponent.translate.instant(res.result),
            })
          }

        }
      }, error: err => {
        this.loading = false;

        this.userEvent.emit({
          error_msg: this.appComponent.translate.instant('error.failed_to_update'),
        })
      }
      // console.log('salesman="%s"', this.f['salesman'].value)
    });
  }

  onTransferPoints() {
    this.submitted = true;

    console.log('onTransferPoints: form.invalid=%s', this.form.invalid)
    if (this.form.invalid) return;

    this.loading =true;

    this.userEvent.emit({
      info_msg: '',
      error_msg: ''
    })

    this.appComponent.rewardService.requestTransferPoints(this.f['targetEmail'].value)
      .pipe(first()).subscribe({
      next: res => {
        this.loading = false;

        if (res.result) {
          if (res.ok) {
            this.userEvent.emit({
              info_msg: this.appComponent.translate.instant(res.result),
              reloadData: true
            })

          } else {
            this.userEvent.emit({
              error_msg: this.appComponent.translate.instant(res.result),
            })
          }

        }
      }, error: err => {
        this.loading = false;

        this.userEvent.emit({
          error_msg: this.appComponent.translate.instant('error.failed_to_update'),
        })
      }
      // console.log('salesman="%s"', this.f['salesman'].value)
    });


  }

  protected readonly _globals = globals;
}
