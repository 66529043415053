<form class="mt-3 text-center" [formGroup]="formFile" method="post" enctype="multipart/form-data" >
  <h2 class="form-signin-heading">{{ 'caption.update_gift' | translate}}</h2>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert"
       [innerHTML]="error_msg">
  </div>

  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg">
  </div>

  <label class="form-label" for="gifts_file" [innerHTML]="'caption.upload_gifts_file' | translate"></label>

  <div class="row g-3 align-items-center" style="display: inline-block">
    <div class="col-auto">
      <input class="form-control form-control-sm" formControlName="gifts_file"
             accept=".csv"
             type="file" id="gifts_file" name="gifts_file" (change)="onSelectFile($event)">
    </div>

    <div *ngIf="fileGift" class="col-auto">
      <button [disabled]="loading" class="btn btn-sm btn-primary btn-block" (click)="onUploadGifts()" >
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ 'caption.upload' | translate}}
      </button>
    </div>

    <div *ngIf="loading" class="progress" role="progressbar" aria-label="Progress" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
      <div class="progress-bar" style="width: {{ uploadProgress }}%"></div>
    </div>
  </div>

  <div *ngIf="fileGift" class="alert-success my-2"
       [innerHTML]="'<b>' +('caption.filename'|translate) + '</b>' + '&nbsp; : &nbsp;' + fileGift.name +'&nbsp; (' +('caption.size'|translate) +'&nbsp; : &nbsp;' + fileSize +')'" ></div>
</form>

<hr class="my-3" />

<form class="mt-1 text-center" [formGroup]="form" >
  <div class="m-2 text-sm-start" >
    <select *ngIf="brandFilterOptions.length > 1" class="form-select-sm mx-3" id="brandFilter" formControlName="brandFilter"
            (change)="changeBrandFilter($event)">
      <option *ngFor="let option of brandFilterOptions" [value]="option.val" [innerHTML]="option.display"></option>
    </select>

  </div>

  <div *ngIf="giftsRecs" class="mt-2">
    <div *ngIf="lastUpdateTime"
      [innerHTML]="('caption.last_upload_time'|translate) +'&nbsp; : &nbsp;' + ( lastUpdateTime | localDateformat:'YYYY-MM-DD hh:mm A')" ></div>

    <table class="table table-secondary table-bordered" >
      <thead class="table-primary fw-bold nrg-table-header">
      <tr>
        <td>#</td>
        <td >{{ 'caption.brand_vendor' | translate}}</td>
        <td >{{ 'caption.giftbrand' | translate}}</td>
        <td >{{ 'caption.gifttype' | translate}}</td>
        <td >{{ 'caption.name' | translate}}</td>
        <td >{{ 'caption.points' | translate}}</td>
      </tr>
      </thead>

      <tbody class="nrg-table-body">
        @for(rec of giftsRecs; let i=$index; track rec.id ) {
          <tr>
            <td>{{ i+1 }}</td>
            <td [innerHTML]="rec.brand"></td>
            <td [innerHTML]="rec.giftbrand"></td>
            <td [innerHTML]="rec.gifttype"></td>
            <td [innerHTML]="rec.name"></td>
            <td [innerHTML]="rec.points"></td>
          </tr>
        }

      </tbody>
    </table>
  </div>
</form>
