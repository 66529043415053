<form class="mt-2 text-center" [formGroup]="form" method="post" enctype="multipart/form-data" >
  <h2 class="form-signin-heading">{{ 'caption.add_points' | translate}}</h2>

  <div *ngIf="user.hasRole('ROLE_ADMIN')" class="alert alert-danger" [innerHTML]="'info.no_brand_cannot_upload_invoice' | translate" ></div>

  <div class="border border-success text-center py-2 my-3 " >
    <div class="form-check d-inline-block" >
      <span class="fw-bold mx-3" [innerHTML]="'caption.action_choices' |translate" ></span>

      <div class="form-check form-check-inline" >
        <input class="form-check-input" type="radio" name="userAction" formControlName="userAction" id="userActionUpload" value="upload" >
        <label class="form-check-label" for="userActionUpload" >{{ 'choice.upload_invoice' |translate }}</label>
      </div>

      <div class="form-check form-check-inline" >
        <input class="form-check-input" type="radio" name="userAction" formControlName="userAction" id="userActionMeetingDate" value="meetingDate" >
        <label class="form-check-label" for="userActionMeetingDate" >{{ 'caption.request_meeting' |translate }}</label>
      </div>

      <div *ngIf="user.points > 0" class="form-check form-check-inline" >
        <input class="form-check-input" type="radio" name="userAction" formControlName="userAction" id="userActionTransferPoints" value="transferPoints" >
        <label class="form-check-label" for="userActionTransferPoints" >{{ 'caption.transfer_points' |translate }}</label>
      </div>
    </div>
  </div>

  <div >
    @switch (userAction) {
      @case ('upload') {
        <div class="row g-3 align-text-bottom">
          <div class="col-4" >
            <div >
              <label class="form-label" for="invoice_number" [innerHTML]="'caption.input_invoice_number' | translate"></label>
            </div>

            <mat-form-field class="form-field-full">
              <mat-label class="sr-only" [innerHTML]="'caption.invoice_number' | translate"></mat-label>
              <input matInput type="text" id="invoice_number" formControlName="invoice_number"
                     placeholder="{{ 'caption.invoice_number' | translate }}"
                     maxlength="{{ _globals.maxFieldLength }}"
                     autofocus
              >
              <mat-icon *ngIf="submitted && f['invoice_number']['errors']" matIconSuffix>error</mat-icon>

              @if(submitted && f['invoice_number']['errors']){
                <mat-error *ngIf="f['invoice_number']['errors']['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
              }
            </mat-form-field>

          </div>

          <div class="col-8">
            <div >
              <label class="form-label" for="invoice_file" [innerHTML]="'caption.upload_invoice_file' | translate"></label>
            </div>

            <div class="row g-3 align-items-center" style="display: inline-block">
              <div class="col-auto">
                <input class="form-control form-control-sm" formControlName="invoice_file"
                       accept="application/pdf"
                       type="file" id="invoice_file" name="invoice_file" (change)="onSelectFile($event)">
              </div>

              <div *ngIf="fileInvoice" class="col-auto">
                <button [disabled]="loading" class="btn btn-sm btn-primary btn-block" (click)="onUploadInvoice()" >
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  {{ 'caption.upload' | translate}}
                </button>
              </div>

              <div *ngIf="loading" class="progress" role="progressbar" aria-label="Progress" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" style="width: {{ uploadProgress }}%"></div>
              </div>
            </div>
          </div>
        </div>
      }

      @case ('meetingDate') {
        <div class="row g-3 align-items-center">
          <div class="col-12" [innerHTML]="'caption.request_meeting_w_sales' | translate" >
          </div>

          <div class="col-auto">
            <mat-form-field>
              <mat-label [innerHTML]="'caption.meeting_date' | translate"></mat-label>
              <input matInput [matDatepicker]="picker" id="meetingDate" formControlName="meetingDate">
              <mat-hint>YYYY-MM-DD</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>

              <mat-error *ngIf="submitted && f['meetingDate']?.['errors']?.['meeting_date_not_set']">
                {{ 'error.meeting_date_unset' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-auto">
            <mat-form-field>
              <mat-label>{{ 'caption.meeting_time'|translate }}</mat-label>
              <select matNativeControl id="meetingTime" formControlName="meetingTime">
                <option *ngFor="let option of meetingTimeOptions" [value]="option.val" [innerHTML]="option.display"></option>
              </select>

              <mat-error *ngIf="submitted && f['meetingTime']?.['errors']?.['meeting_time_not_set']">
                {{ 'error.meeting_time_unset' | translate }}
              </mat-error>
              <mat-error *ngIf="submitted && f['meetingTime']?.['errors']?.['before_now']">
                {{ 'error.meeting_before_now' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-auto">
            <mat-form-field class="salesman_select">
              <mat-label>{{ 'caption.salesman'|translate }}</mat-label>
              <select matNativeControl id="salesman" formControlName="salesman">
                <option *ngFor="let option of salesmanOptions" [value]="option.val" [innerHTML]="option.display"></option>
              </select>

              <mat-error *ngIf="submitted && f['salesman']?.['errors']?.['required']">
                {{ 'error.salesman_is_required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="d-inline-block mt-3">
          <button [disabled]="loading" class="btn btn-sm btn-primary btn-block" (click)="onRequestMeeting()" >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{ 'caption.request_meeting' | translate}}
          </button>
        </div>
      }

      @case ('transferPoints') {
        <div >
          <div class="" [innerHTML]="'caption.transfer_points_to_other' | translate" >
          </div>

          <div class="form-field mt-2 d-inline-block">
            <mat-form-field class="form-field-full">
              <mat-label class="sr-only" [innerHTML]="'caption.transfer_to_email' | translate"></mat-label>
              <input matInput type="text" id="targetEmail" formControlName="targetEmail"
                     placeholder="{{ 'caption.email' | translate }}"
                     maxlength="{{ _globals.maxFieldLength }}"
                     autofocus
              >
              <mat-icon *ngIf="submitted && f['targetEmail']['errors']" matIconSuffix>error</mat-icon>

              @if(submitted && f['targetEmail']['errors']){
                <mat-error *ngIf="f['targetEmail']['errors']['required']">{{ 'error.email_is_reqd' | translate }}</mat-error>
                <mat-error *ngIf="f['targetEmail']['errors']['email']">{{ 'error.invalid_email_format' | translate }}</mat-error>
                <mat-error *ngIf="f['targetEmail']['errors']['diff_domain']">{{ 'error.wrong_email_domain' | translate }}</mat-error>
              }
            </mat-form-field>
          </div>
        </div>

        <div class="d-inline-block mt-3">
          <button [disabled]="loading" class="btn btn-sm btn-primary btn-block" (click)="onTransferPoints()" >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{ 'caption.request_transfer' | translate}}
          </button>
        </div>

      }
    }
  </div>

  <div *ngIf="fileInvoice" class="alert-success my-2"
       [innerHTML]="'<b>' +('caption.filename'|translate) + '</b>' + '&nbsp; : &nbsp;' + fileInvoice.name +'&nbsp; (' +('caption.size'|translate) +'&nbsp; : &nbsp;' + fileSize +')'" ></div>
</form>
