<nav class="navbar navbar-dark bg-black position-relative justify-content-center">
  <a class="navbar-brand " href="/" >
    <img src="/assets/img/nrglogo.png" width="32" height="16" class="d-inline-block align-baseline me-1" alt="" />
    {{ title }}
  </a>

  <div *ngIf="showLangSelection" class="nav-item align-self-end position-absolute me-2 top-50 end-0 translate-middle-y">
    <select class="form-select-sm" id="locales" (change)="change_lang($event)" [(ngModel)]="initialLangId" >
      @for(lang of Languages; track lang) {
        <option value="{{ lang }}" >{{ 'language_name_' +lang | translate }} </option>
      }
    </select>
  </div>

  <div class="nav-item align-self-start position-absolute ms-2 top-50 start-0 translate-middle-y text-white">
    @if(user.hasRole('ROLE_ADMIN')) {
    <div style="font-size: smaller" >
      <span *ngIf="user.hasRole('ROLE_SUPERUSER'); else showAdmin" >[Superuser]</span>
      <ng-template #showAdmin>
        <span #showAdmin>[Admin]</span>
      </ng-template>
    </div>
    }

    <div *ngIf="swVersion" style="font-size: smaller" >
      {{ 'ver: ' +swVersion }}
    </div>

  </div>

  <div *ngIf="user.email" class="nav-item align-self-start position-absolute ms-2 top-50 end-0 translate-middle-y text-white">
    <div class="text-warning me-1">{{ user.fullName }}</div>
    <div class="text-warning me-1">{{ user.email }}</div>
  </div>

</nav>
