export const globals = {
  minPasswordLength: 8,
  maxPasswordLength: 30,
  maxFieldLength:100,
  maxReasonLength:300,
  minFieldNameLength: 2,
  minFieldMobileLength: 8,
  emailVerificationCodeLen: 6,
  display_time_zone: 'GMT+8',
  date_str_tz_offset_suffix: '+08:00',  // used to append to json'ed date str for date conversion by Date()
};

///////////////////// auditlog
export const ActionType: {[key:string]: string} = {
  LoginOk: "LoginOk",
  LoginFailed: "LoginNG",
  VerifyCode: "VerifyCode",
  RegUserOk: 'RegUserOk',
  RegUserNG: 'RegUserNG',
  ApproveUser: 'ApproveUser',
  RejectUser: 'RejectUser',
  UpdateUserOk: "UpdUserOk",
  UpdateUserNg: "UpdUserNg",
  UploadInvoice: "UpldInvoice",
  UpdatePointsOk: 'UpdatePtsOk',
  UpdatePointsNG: 'UpdatePtsNG',
  UploadGiftOk: 'UpldGiftOk',
  UploadGiftNG: 'UpldGiftNG',
  AddRedeemOrderOk: 'AddRedeemOrderOk',
  AddRedeemOrderNG: 'AddRedeemOrderNG',
  UpdateRedeemItem: 'UpdateRedeemItem',
  UpdateRedeemOrder: 'UpdateRedeemOrder',
  UpdateSettingsOk: "UpdSettingsOk",
  UpdateSettingsNg: "UpdSettingsNg",
  Logout: "Logout",
  AddUserOk: "AddUserOk",
  AddUserNG: "AddUserNG",
  ForgotPassword:"ForgotPassword",
  ReqMeetSales:"ReqMeetSales",
  ReqTxPoints:"ReqTxPoints",
  Dispute:"Dispute",
  DisputeSettled:"DisputeSettled",
}

export const DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
