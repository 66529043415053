import {Component, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule, ValidationErrors, ValidatorFn,
  Validators
} from "@angular/forms";
import {IAuditLog, User} from "../../models";
import {ActionType, DATE_FORMATS, globals} from "../../conf/globals";
import {AppComponent} from "../app.component";
import {Router} from "@angular/router";
import {MatInputModule} from "@angular/material/input";
import {NgForOf, NgIf} from "@angular/common";
import {TextFieldModule} from "@angular/cdk/text-field";
import {TranslateModule} from "@ngx-translate/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import {LocalDateformatPipe} from "../../helpers/local-dateformat.pipe";
import {first} from "rxjs";
import {compareFunc} from "../../helpers/compareFunc";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'app-audit-log',
  standalone: true,
  imports: [
    MatInputModule,
    NgIf,
    ReactiveFormsModule,
    TextFieldModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    FormsModule,
    MatNativeDateModule,
    NgForOf,
    LocalDateformatPipe,
    MatButtonModule,
  ],
  providers: [
    { provide: DateAdapter, useClass:MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
  templateUrl: './audit-log.component.html',
  styleUrl: './audit-log.component.css'
})

export class AuditLogComponent implements OnInit {
  error_msg = '';
  info_msg = '';
  form: FormGroup;

  loading = false;
  submitted = false;
  user: User = this.appComponent.userService.currentUserValue;

  protected readonly _globals = globals;

  actionsOptions: { val:string, display: string }[] = [  ]

  get f() {return this.form.controls;}

  recAuditLogs: Partial<IAuditLog>[] =[];
  filteredAuditLogs: Partial<IAuditLog>[] =[];

  dateRange =new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  }, [ this.checkDateRangeValidator() ]);

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private router: Router
  ) {
    this.form = this.formBuilder.group({
      user: [''],
      company: [''],
      action: [''],
      details: [''],
      dateRange: this.dateRange,
    });
  }

  ngOnInit() {
    // build actionsOptions
    for(let [key, value] of Object.entries(ActionType)) {
      this.actionsOptions.push({val: value, display: key});
    }
    this.actionsOptions.sort( (a,b) => compareFunc(a.display, b.display));
    this.actionsOptions.unshift({val: '', display: 'Any'});

  }

  checkDateRangeValidator() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate =this.dateRange?.controls['start'],
        endDate = this.dateRange?.controls['end'];

      // console.log("checkDateRangeValidator: start=%s, end=%s", startDate, endDate);

      if(!startDate?.value || !endDate?.value) {
        const error ={ dates_not_set: {value: true}};
        control.markAllAsTouched();
        startDate?.setErrors(error);
        endDate?.setErrors(error)

        return error;
      }

      startDate?.setErrors(null);
      endDate?.setErrors(null)

      return null;  // passed
    }
  }

  onClickApplyFilter(event: any) {
    event.preventDefault();
    this.filterData();
  }

  filterData() {
    console.log('>>> filterData()');

    this.loading =true;

    let wantedUserParts: string[] =this.f['user'].value.toLowerCase().replace(/\s+/,' ').split(' '),
      wantedCompanyParts: string[] =this.f['company'].value.toLowerCase().replace(/\s+/,' ').split(' '),
      wantedDetailsParts: string[] =this.f['details'].value.toLowerCase().replace(/\s+/,' ').split(' '),
      wantedAction:string =this.f['action'].value;

    if(wantedUserParts.length === 1 && wantedUserParts[0].length === 0) wantedUserParts =[];
    if(wantedCompanyParts.length === 1 && wantedCompanyParts[0].length === 0) wantedCompanyParts =[];
    if(wantedDetailsParts.length === 1 && wantedDetailsParts[0].length === 0) wantedDetailsParts =[];

    if(wantedUserParts.length > 0 || wantedCompanyParts.length > 0 || wantedDetailsParts.length > 0 || wantedAction) {
      this.filteredAuditLogs = this.recAuditLogs.filter((rec, i) => {
        // check email / name
        if (wantedUserParts.length > 0) {
          const email_n_name = (rec.email?.toLowerCase() ?? '') + (rec.fullName?.toLowerCase() ?? '');
          if (!email_n_name || !wantedUserParts.every(part => email_n_name.indexOf(part) >= 0)) return false;
        }

        if (wantedCompanyParts.length > 0) {
          const company = rec.company?.toLowerCase() ?? '';
          if (!company || !wantedCompanyParts.every(part => company.indexOf(part) >= 0)) return false;
        }

        if (wantedDetailsParts.length > 0) {
          const details = rec.details?.toLowerCase() ?? '';
          if (!details || !wantedDetailsParts.every(part => details.indexOf(part) >= 0)) return false;
        }

        return !(wantedAction && rec.action !== wantedAction);
      })
    } else {
      this.filteredAuditLogs = this.recAuditLogs;
    }

    this.submitted = this.loading =false;
  }

  reloadData() {
    // console.log('>>> reloadData()');

    this.loading =true;

    // modify end date to 23:59:59
    // const sysTzStartDate = new Date(moment(this.dateRange.get('start')?.value).format('YYYY-MM-DDT00:00:00') + globals.date_str_tz_offset_suffix);
    // const sysTzEndDate = new Date(moment(this.dateRange.get('end')?.value).format('YYYY-MM-DDT23:59:59.999') + globals.date_str_tz_offset_suffix);

    const sysTzStartDate = new Date( LocalDateformatPipe.formatDateFunc(this.dateRange.get('start')?.value, 'YYYY-MM-DDT00:00:00') + globals.date_str_tz_offset_suffix);
    const sysTzEndDate = new Date(LocalDateformatPipe.formatDateFunc(this.dateRange.get('end')?.value, 'YYYY-MM-DDT23:59:59.999') + globals.date_str_tz_offset_suffix);

    // console.log('>>> reloadData1: start=%s, end=%s', this.dateRange.get('start')?.value, this.dateRange.get('end')?.value);
    // console.log('>>> reloadData2: start=%s, end=%s', sysTzStartDate, sysTzEndDate);

    this.appComponent.systemService.getAuditLog(sysTzStartDate, sysTzEndDate)
      .pipe(first()).subscribe({
      next: (res) => {
        // done
        if(res.ok) {
          // console.log('>>> res = %o', res);

          this.recAuditLogs = res.logs;
          this.filterData();
        } else {
          this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
          this.submitted = this.loading =false;
        }

      }, error: err => {
        this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
        this.submitted = this.loading =false;
      }
    });

  }

  onSubmit() {
    this.submitted =true;

    if(this.form.invalid) return;

    this.reloadData();
  }
}
