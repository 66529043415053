<h2 *ngIf="data.title" mat-dialog-title [innerHTML]="data.title" ></h2>
<mat-dialog-content>
  <p [innerHTML]="data.msg" ></p>
  <mat-form-field *ngIf="data.input_label">
    <mat-label [innerHTML]="data.input_label" ></mat-label>
    <input matInput [(ngModel)]="data.input_ans">
  </mat-form-field>
</mat-dialog-content>

<div mat-dialog-actions align="center">
  <button *ngIf="data.no_button" mat-button (click)="onNoClick()" [innerHTML]="data.no_button" ></button>
  <button *ngIf="data.yes_button" mat-button [mat-dialog-close]="data.result_yes" cdkFocusInitial [innerHTML]="data.yes_button"></button>
</div>
