import {Component, DoCheck, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router, RouterOutlet} from '@angular/router';
import {Title} from "@angular/platform-browser";
import {NavbarComponent} from "./navbar/navbar.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {Languages} from "./app.config";
import {UserNavbarComponent} from "./user-navbar/user-navbar.component";
import {UserService} from "../services";
import {User} from "../models";
import {environment} from "../environments/environment";
import {first, Subscription} from "rxjs";
import { ActivatedRoute } from '@angular/router';
import {RewardService} from "../services";
import { BnNgIdleService } from 'bn-ng-idle';
import {HttpService} from "../services/http-service";
import {SystemService} from "../services/SystemService";
import {FooterComponent} from "./footer/footer.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule,
    RouterOutlet,
    NavbarComponent,
    TranslateModule,
    UserNavbarComponent, FooterComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit, DoCheck, OnDestroy {
  swVersion = '';

  constructor(
    private readonly titleService: Title,
    public translate: TranslateService,
    public userService: UserService,
    public rewardService: RewardService,
    public systemService: SystemService,
    private router: Router,
    private route: ActivatedRoute,
    private bnIdle: BnNgIdleService,
    private httpService: HttpService,
  ) {
    translate.addLangs(Languages);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang && Languages.indexOf(browserLang) >= 0 ? browserLang : 'en');

    this.allSubscriptions.push(this.userService.currentUser.subscribe(u => {
      this.user = u;
      // console.log('>>> app-root: sub: user=%o', u);
    }));

    // check if server session exists
    this.userService.checkSession().pipe(first()).subscribe({
      next: (u) => {
        // this.user = u;
        // console.log('>>> app-root: constructor.checkSession: user=%o', u);
      },
      error: err => {
        // console.log('app-root.checkSession(): err=%o', err);
      }
    });

    // arbitrary initial timeout of 1 hour
    // console.log('>>> session timer starts watching');
    this.bnIdle.startWatching(60*60).subscribe((res) => {
      if(res) {
        // console.log("%s: >>> session expired: email=%s", new Date(), this.user.email);
        if(this.user.email) {
          this.userService.logout().pipe(first()).subscribe({
            next: () => {},
            error: (err) => { this.userService.kickoutCurrentUser() }
          });
        }
      }
    });

    this.httpService.accessHttp.subscribe(
      (res) => {
        // console.log('>>> accessHttp: %s', res);
        this.restartSessionTimer();
      });
  }


  restartSessionTimer() {
    if(this.user.email) {
      const timeoutInMins = this.user?.sessionTimeout ?? 0;
      if(!timeoutInMins) return;

      this.bnIdle.resetTimer(timeoutInMins * 60);
      // console.log("%s: >>> restart session timer: %s mins, email=%s", new Date(), timeoutInMins, this.user.email);
    }
  }

  ngOnDestroy(): void {
    while(this.allSubscriptions.length) {
      this.allSubscriptions.pop()?.unsubscribe();
    }
  }

  setTitle() {
    const hostname =window.location.hostname;
    let titleTag ='unknown.page.title';
    switch(hostname.toLowerCase()) {
      case 'localtest':
      case 'localhost':
        titleTag ='localtest.page.title';
        break;

      case 'jreward.com':
      case 'www.jreward.com':
        titleTag ='jreward.com.page.title';
        break;

      default:
        titleTag ='default.page.title';
        break;
    }

    this.title = this.translate.instant(titleTag);
  }

  title = '';
  user: User = new User();
  catalog_link ='';

  ngOnInit() {
    // console.log('app-root:ngOnInit: %s', new Date());

    // console.log('host=%s', window.location.hostname);
    if(!environment.production) console.log('%s: api=%s', new Date(), environment.api_url);
  }

  allSubscriptions: Subscription[] =[];

  ngDoCheck() {
    // console.log('root:ngDoCheck');

    this.setTitle();
    this.titleService.setTitle(this.title);
  }

}
