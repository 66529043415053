import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {
  AbstractControl, FormArray,
  FormBuilder, FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn
} from "@angular/forms";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {globals} from "../../conf/globals";
import {AppComponent} from "../app.component";
import {Router} from "@angular/router";
import {IResultResp, IResultUsersResp, IUser} from "../../models";
import {LocalDateformatPipe} from "../../helpers/local-dateformat.pipe";
import {FilterConditions} from "../../services";
import {compareFunc} from "../../helpers/compareFunc";
import {first} from "rxjs";

@Component({
  selector: 'app-approve-new-user',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    TranslateModule,
    NgClass,
    NgForOf,
    LocalDateformatPipe
  ],
  templateUrl: './approve-new-user.component.html',
  styleUrl: './approve-new-user.component.css'
})
export class ApproveNewUserComponent implements OnInit {
  error_msg ='';
  info_msg ='';
  loading = false;
  submitted = true;

  users: Partial<IUser>[] =[];

  // for determining whether to display approval block
  // key=idx (string): show: boolean
  userApprovalBlocks: any = {};

  // key=idx (string): value: 'approve'|'reject'
  gAction: any ={};

  // key=idx (string): value: boolean
  approvalDone: any ={};

  // key=idx (string): value: boolean
  approvalResult: any = {};

  // action:'approve'|'reject' ='approve'; // approve or reject
  form: FormGroup;

  get f() { return this.form.controls; }
  get aliases() { return this.form.get('aliases') as FormArray }

  protected readonly _globals = globals;
  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private router: Router,
              private ref: ChangeDetectorRef
  ) {

    // console.log('>>> ApproveNewUserComponent.constructor()');
    this.form = this.formBuilder.group({
      aliases: this.formBuilder.array([]),
    });

  }

  reloadData() {
    const conditions : FilterConditions[] = [
      { var: 'isNew', op: '=', value: true}
    ];
    const brand =this.appComponent.userService.currentUserValue.brand;
    if(brand && brand !== '*') {
      conditions.push({ var: 'brand', op: 'IN', value: this.appComponent.userService.currentUserValue.brand })
    }

    // clear form array
    this.aliases.controls =[];

    this.appComponent.userService.getAllUsers(conditions)
      .pipe().subscribe({
        next: (res: IResultUsersResp) => {
          console.log('>>> res: %o', res);

          if(res.ok && res.users) {
            // sort by createOn desc
            this.users = res.users.sort(
              (b, a) => {
                if (a.createdOn && b.createdOn) return compareFunc(a.createdOn, b.createdOn);
                return -1;
              });

            for(let i=0; i < this.users.length; i++) {
              this.aliases.push(new FormControl('', this.emptyReasonValidator(i.toString())));
            }
          }
        },
        error: (err) => {
          // console.log(err);
          console.log('status=%s', err?.status);

          if(err?.status === 401) {
            this.router.navigateByUrl('');
          }
        }
      })

  }

  emptyReasonValidator(idx : string) : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // console.log("emptyReasonValidator: actions(%s)=%s, reason='%s'",
      //   idx, this.gAction[idx], control.value);

      if(this.gAction[idx] === 'reject' && control.value.trim().length === 0) {
        console.log('emptyReasonValidator: return error %s', idx)
        return {'reasonEmpty': {value: idx}};
      }
      return null;  // passed
    }
  }

  ngOnInit(): void {
    // conditions? : [{ var: 'isNew', op: '=', value: true}, { var: 'brand', op: '=', value: 'JABRA' } ]
    this.reloadData();
  }

  onClickUser(idx: string) {
    // console.log('clicked');

    if (this.userApprovalBlocks[idx]) {
      // hide the block
      delete this.userApprovalBlocks[idx];
    } else {
      // show the block
      this.userApprovalBlocks = {};
      this.userApprovalBlocks[idx] = true;
    }
  }

  // action: 'approve', or 'reject'
  onSubmit(action:'approve'|'reject', idx:string, email:string) {
    this.approvalResult[idx] ='';

    this.gAction[idx] = action;
    this.aliases.get(idx)?.updateValueAndValidity();
    this.ref.detectChanges();

    // console.log(">>> onSubmit: action=%s, reason='%s', error=%o",
    //   this.gAction[idx], this.aliases.get(idx)?.value,
    //   this.aliases.get(idx)?.['errors']);

    // this.action = action;

    this.submitted =true;

    if(action === 'reject' && this.aliases.get(idx)?.['errors']) {
      // need provide a reason for reject
      console.log('>>> onSubmit: submitted=%s, error=%o',
        this.submitted, this.aliases.get(idx)?.['errors'] ? 'has error' : 'no error');
      return;
    }

    this.loading =true;

    const brand =this.appComponent.user.brand;

    this.appComponent.userService.approveNewUser(email, action, this.aliases.get(idx)?.value)
      .pipe(first()).subscribe({
        next: (res: IResultResp) => {
          // done
          this.approvalDone[idx] = res.ok;

          // successful, result msg is i18n id
          this.approvalResult[idx] = this.appComponent.translate.instant(res.result);

          this.loading = this.submitted = false;

        }, error: (err:any) => {
          this.loading = this.submitted = false;

          this.approvalResult[idx] = err['error_msg'] ?? 'error.approval_failed';
          this.approvalDone[idx] = false;
        }
      }
    );
  }
}
