<form [formGroup]="form" class="form-signin text-center" (ngSubmit)="onSubmit()" >

  <h2 class="form-signin-heading">{{ 'caption.forgot_password' | translate}}</h2>

  <div class="text-warning my-3" [innerHTML]="'caption.forgot_password_instructions'|translate"
       ></div>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert"
       [innerHTML]="error_msg"></div>

  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg"></div>

  <div class="text-start d-inline-block" >
    <div class="form-field">
      <mat-form-field class="form-field-full">
        <mat-label class="sr-only" [innerHTML]="'caption.email' | translate"></mat-label>
        <input matInput type="text" id="email" formControlName="email"
               placeholder="{{ 'caption.email' | translate }}"
               maxlength="{{ _globals.maxFieldLength }}"
               autofocus
        >
        <mat-icon *ngIf="submitted && f['email']['errors']" matIconSuffix>error</mat-icon>

        @if(submitted && f['email']['errors']){
          <mat-error *ngIf="f['email']['errors']['required']">{{ 'error.email_is_reqd' | translate }}</mat-error>
          <mat-error *ngIf="f['email']['errors']['email']">{{ 'error.invalid_email_format' | translate }}</mat-error>
        }
      </mat-form-field>
    </div>
  </div>

  <div class="form-group">
    <button [disabled]="loading" class="btn btn-sm btn-primary btn-block" type="submit">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ 'caption.submit' | translate }}
    </button>
  </div>

</form>

