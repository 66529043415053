import {Component, DoCheck, Input, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, NgIf, NgOptimizedImage} from "@angular/common";
import {AppComponent} from "../app.component";
import {Languages } from "../app.config";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {globals} from "../../conf/globals";
import {ActivatedRoute} from "@angular/router";
import packageJson from '../../../package.json';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    NgIf,
    NgOptimizedImage,
    FormsModule,
    TranslateModule,
    CommonModule
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})

export class NavbarComponent implements OnInit, OnDestroy {
  @Input() title! : string;

  protected readonly _globals = globals;
  swVersion ='';
  allSubscriptions: Subscription[] = [];

  initialLangId: string = '';
  user = this.appComponent.userService.currentUserValue;

  showLangSelection =false;

  constructor(private appComponent: AppComponent,
              private route: ActivatedRoute) {
    this.allSubscriptions.push(appComponent.userService.currentUser.subscribe(u => {
      this.user = u;
      // console.log('>>> nav: sub: user=%o', u);
    }));

    const params = new URLSearchParams(window.location.search);
    if (params.get('version') === 'Blackpink') {
      //console.log('### version=%s', params.get('version'));

      this.swVersion = packageJson.version +', prod=' +(environment.production ? 'true' : 'false');
    }

    //debug
    // if(params.get('api')) {
    //   console.log('api_url=%s, prod=%s', environment.api_url, environment.production);
    // }
  }

  ngOnDestroy(): void {
    while(this.allSubscriptions.length) {
      this.allSubscriptions.pop()?.unsubscribe();
    }
  }

  change_lang(event:any)  {
    this.appComponent.translate.use(event.target.value);

    // this.localeId = this.appComponent.translate.currentLang;
    //console.log('change_lang: %s', this.localeId);
  };

  protected readonly Languages = Languages;

  ngOnInit() {
    this.initialLangId =this.appComponent.translate.currentLang;
  }

  // ngDoCheck() {
  //   this.title = this.appComponent.title;
  // }
}
