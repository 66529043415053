import {Component, OnDestroy} from '@angular/core';
import {NgIf} from "@angular/common";
import {CookieService} from "ngx-cookie-service";
import {first, Subscription} from "rxjs";
import {AppComponent} from "../app.component";
import {User} from "../../models";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {IDialogPromptData} from "../../models/DialogPromptData";
import {SystemService} from "../../services/SystemService";
import {DialogPromptOkComponent} from "../dialog-prompt-ok/dialog-prompt-ok.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    RouterLinkActive,
    TranslateModule,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent implements OnDestroy {
  showCookieConsent = true;
  cookieConsentName = 'cookieConsent';
  allSubscriptions: Subscription[] = [];
  user = new User()
  dialogPromptData: Partial<IDialogPromptData> = {}
  tnc =''

  constructor(private cookieService:CookieService,
              private appComponent: AppComponent,
              public systemService: SystemService,
              public dialog: MatDialog) {
    // console.log('>>> footer.constructor')

    this.allSubscriptions.push(appComponent.userService.currentUser.subscribe(u => {
      this.user = u;
      // console.log('>>> nav: sub: user=%o', u);
    }));

    let cookieConsent = this.cookieService.get(this.cookieConsentName);
    // console.log('>>> cookieConsent=%s', cookieConsent);
    this.showCookieConsent = !cookieConsent;
  }

  onClickDismissCookieConsent() {
    this.cookieService.set(this.cookieConsentName, '1')
    this.showCookieConsent = false;
  }

  ngOnDestroy(): void {
    while(this.allSubscriptions.length) {
      this.allSubscriptions.pop()?.unsubscribe();
    }
  }

  showTnC() {
    this.dialog.open(DialogPromptOkComponent, {data: this.dialogPromptData} )
  }

  onClickTnC() {
    this.cookieService.delete(this.cookieConsentName)

    this.systemService.getTnC().pipe(first()).subscribe({
      next: (resp) => {
        const contents =resp.result

        // turn 1st line to bold
        this.tnc = contents.split("\n").map((s, i) => {
          if(i === 0) s = `<h4><b><u>${s}</u></b></h4>`
          return s + '<br>'
        }).join('</b>')

        // fill dialog data
        this.dialogPromptData = {
          msg: this.tnc,
          yes_button: this.appComponent.translate.instant('caption.close'),
        }

        this.showTnC()
      }, error: (err) => {
      }
    })

  }
}
