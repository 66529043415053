import {Component, DoCheck, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {NgIf} from "@angular/common";
import {IDialogPromptData} from "../../models/DialogPromptData";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-dialog-prompt-ok',
  standalone: true,
  templateUrl: './dialog-prompt-ok.component.html',
  styleUrl: './dialog-prompt-ok.component.css',
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    NgIf,
  ],
})
export class DialogPromptOkComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogPromptOkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Partial<IDialogPromptData>,
    public translate: TranslateService,
  ) {
    // console.log('>>> DialogPromptOkComponent: data=%o', this.data)
    // if(!data['yes_button']) data['yes_button'] = this.translate.instant('caption.yes')
    // if(!data['no_button']) data['no_button'] = this.translate.instant('caption.no')
  }

  onNoClick(): void {
    // blank input_ans
    this.data.input_ans = '';

    this.dialogRef.close(this.data.result_no);
  }

}
