// import util from 'util';

export interface IUser {
  id: string,
  email: string;
  password: string;
  profile: string;
  approval: string,
  brand: string|string[];
  fullName: string;
  mobile: string,
  company: string;
  isNew: boolean,
  isEnabled: boolean;
  isAdmin: boolean;
  isSuperuser: boolean;
  lastPasswordUpdate: Date;
  isAccNonLocked: boolean;
  isAccNonExpired: boolean;
  isPasswordNonExpired: boolean;
  points: number,
  createdOn: Date;
  sessionTimeout: number; // timeout in mins
}

const EmptyUser  ={
  id: '',
  email: '',
  password: '',
  profile: '',
  approval: '',
  brand: '',
  fullName: '',
  mobile: '',
  company: '',
  isNew: false,
  isEnabled: false,
  isAdmin: false,
  isSuperuser: false,
  lastPasswordUpdate: new Date(0),
  isAccNonLocked: false,
  isAccNonExpired: false,
  isPasswordNonExpired: false,
  points: 0,
  createdOn: new Date(0),
  sessionTimeout: 0
};

export function getRoleName(u:Partial<IUser>|undefined) : string {
  if(u?.isSuperuser) return 'ROLE_SUPERUSER';
  if(u?.isAdmin) return 'ROLE_ADMIN';
  if(u?.email) return 'ROLE_USER';

  return '';
}

export class User {
  _user: IUser;
  private _token: string ='';

  constructor(user?: Partial<IUser> | null) {
    if(user) {
      this._user = { ...EmptyUser, ...user};
      // console.log('User:constructor: _user.email=%o', this._user);
    } else {
      this._user = EmptyUser;
    }
  }

  loadUser(user : IUser) {
    this._user = { ...EmptyUser, ...user};
  }

  hasRole(role : string): boolean {
    // console.log('hasRole(%s): email=%s, isAdmin=%s, isSuper=%s', role, this._user.email, this._user.isAdmin, this._user.isSuperuser);

    switch(role) {
      case 'ROLE_SUPERUSER':
        return this._user.isSuperuser;
      case 'ROLE_ADMIN':
        return this._user.isAdmin || this._user.isSuperuser;
      case 'ROLE_USER':
        return !!this._user.email;
    }
    return false;
  }

  public get email() {
    return this._user.email;
  }

  public get fullName() {
    return this._user.fullName;
  }

  public set fullName(s:string) {
    this._user.fullName =s
  }

  public get company() {
    return this._user.company;
  }
  public set company(s:string) {
    this._user.company =s
  }

  public get brand() {
    return this._user.brand;
  }

  public get mobile() {
    return this._user.mobile;
  }
  public set mobile(s:string) {
    this._user.mobile =s
  }

  public get isNew() {
    return this._user.isNew;
  }
  public get isEnabled() {
    return this._user.isEnabled;
  }
  public get isAdmin() {
    return this._user.isAdmin;
  }
  public get isSuperuser() {
    return this._user.isSuperuser;
  }
  public get lastPasswordUpdate() {
    return this._user.lastPasswordUpdate;
  }
  public get isAccNonLocked() {
    return this._user.isAccNonLocked;
  }
  public get isAccNonExpired() {
    return this._user.isAccNonExpired;
  }
  public get isPasswordNonExpired() {
    return this._user.isPasswordNonExpired;
  }
  public get createdOn() {
    return this._user.createdOn;
  }

  public get points() {
    return this._user.points;
  }
  public get sessionTimeout() {
    return this._user.sessionTimeout;
  }

  get token(): string {
    return this._token;
  }

  set token(value: string) {
    this._token = value;
  }

}
