import {Component, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {NgClass, NgIf} from "@angular/common";
import {Router} from "@angular/router";
import {globals} from "../../conf/globals";
import {AppComponent} from "../app.component";
import {first, Subscription} from "rxjs";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
// import { KeepHtmlPipe } from "../../helpers/keep-html.pipe";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    NgIf,
    NgClass,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnDestroy {
  error_msg='';
  loginForm: FormGroup;
  loading = false;
  submitted = false;

  get f() { return this.loginForm.controls; }

  protected readonly _globals = globals;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private router: Router
              ) {

    // console.log('>>> LoginComponent.constructor()');

    this.loginForm = this.formBuilder.group({
      'email': ['', Validators.compose(
        [ Validators.required, Validators.email, Validators.maxLength(globals.maxFieldLength)]
      )],
      'password': ['', Validators.compose(
        [ Validators.required ]
      )],
    });

    this.allSubscriptions.push(appComponent.userService.currentUser.subscribe(u => {
      this.user = u;
      // console.log('>>> login: sub: user=%o', u);
      if(u.hasRole('ROLE_USER')) {
        // user has logged in successfully, reload root
        this.router.navigateByUrl('/');
      }
    }));

  }

  allSubscriptions: Subscription[] = [];

  user = this.appComponent.userService.currentUserValue;
  passwordVisible =false;

  onClickRevealPassword(event:any) {
    // console.log('onClickRevealPassword: target=%o', event.target);

    event.preventDefault();
    // Prevent revealing the password when enter button is pressed.
    if (event.target instanceof HTMLSpanElement) {
      this.passwordVisible = !this.passwordVisible;
    }
  }

  ngOnDestroy(): void {
    // console.log('>>> LoginComponent.ngOnDestroy()');

    while(this.allSubscriptions.length) {
      this.allSubscriptions.pop()?.unsubscribe();
    }
  }

  onRegUser(): void {
    this.loading =true;
    this.router.navigateByUrl('/register_user', {skipLocationChange:true});
  }

  onClickForgotPassword() {
    this.router.navigateByUrl('/forgot_password', {skipLocationChange:true});
  }

  onSubmit() {
    this.submitted =true;

    if(this.loginForm.invalid) {
    //   if(this.f['email']['errors']) console.log('!!! email error: %o', this.f['email']['errors']);
    //   if(this.f['password']['errors']) console.log('!!! password error: %o', this.f['password']['errors']);
      return;
    }

    this.loading =true;
    this.appComponent.userService.login(this.f['email']?.value, this.f['password']?.value)
      .pipe(first()).subscribe({
        next: () => {
          // done
          // this.router.navigateByUrl('/welcome', {skipLocationChange:true});

          this.appComponent.restartSessionTimer();
        }, error: err => {
          this.loading = false;
          this.error_msg = this.appComponent.translate.instant('error.login_failed');
        }
      }
    );
  }

}
