
  <div class="footer container" >
    <div *ngIf="showCookieConsent" id="cookiesConsent" class="card m-3 fs-6">
      <div class="card-body bg-black text-white">
        <div class="row align-items-center">
          <div class="col-11 text-start ">
            <b>THIS WEBSITE USES COOKIES.</b><br>
            We use cookies to store non-personal information necessary for the functionality of the website.
            If you continue browsing the site you consent to the use of cookies on this website.
          </div>

          <div class="col text-center mt-2">
            <button class="btn-outline-dark btn-sm " type="button"
                    (click)="onClickDismissCookieConsent()"
            >Dismiss</button>
          </div>
        </div>
      </div>
    </div>

  <div class="position-relative" *ngIf="user.hasRole('ROLE_USER')" >
    <div class="text-end">
      <a (click)="onClickTnC()" href="javascript:void(0)" >{{ 'caption.terms_n_conds' | translate }}</a>
    </div>
  </div>
</div>

