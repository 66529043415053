import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {MyMissingTranslationHandler} from "../helpers/MyMissingTranslationHandler";
import {HttpAccessInterceptorProvider} from "../helpers/http-interceptor";
import { provideAnimations } from '@angular/platform-browser/animations';
import {CookieService} from "ngx-cookie-service";
import packageJson from "../../package.json";


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  // console.log('>>> HttpLoaderFactory:', packageJson.version)

  return new TranslateHttpLoader(httpClient, '/assets/i18n/', '.json?ver=' +packageJson.version);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(HttpClientModule),
    HttpAccessInterceptorProvider,
    CookieService,
    importProvidersFrom(TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        },
        missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
        useDefaultLang: false
    })),
    provideAnimations()
]
};

// supported languages
export const Languages : string[] = [ 'en', 'tw' ];
