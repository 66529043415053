<form *ngIf="showTableForm" [formGroup]="form" class="text-center" >

  <h2 class="form-signin-heading my-3">{{ 'caption.useramdin.description' | translate}}</h2>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert"
       [innerHTML]="error_msg">
  </div>

  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg">
  </div>

  <div class="form-group">
    <div class="row g-3 align-text-top">
      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.user' | translate "></mat-label>
          <input matInput type="text" id="user" formControlName="user"
                 placeholder="{{ 'caption.enter_user_for_searching' | translate }}"
                 maxlength="{{ _globals.maxFieldLength }}"
                 (keydown.enter)="$event.preventDefault()"
          >
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.company' | translate "></mat-label>
          <input matInput type="text" id="company" formControlName="company"
                 placeholder="{{ 'caption.enter_company_for_searching' | translate }}"
                 maxlength="{{ _globals.maxFieldLength }}"
                 (keydown.enter)="$event.preventDefault()"
          >
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.brand' | translate "></mat-label>
          <input matInput type="text" id="brand" formControlName="brand"
                 placeholder="{{ 'caption.enter_brand_for_searching' | translate }}"
                 maxlength="{{ _globals.maxFieldLength }}"
                 (keydown.enter)="$event.preventDefault()"
          >
        </mat-form-field>
      </div>

      <div class="col-auto ">
        <button class="btn btn-sm btn-primary mt-3"
                (click)="onClickApplyFilter($event)"
                type="button">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ 'caption.filter_auditlog' | translate }}
        </button>
      </div>

    </div>

    <div *ngIf="filteredRecUsers" class="mt-2">
      <table class="table table-secondary table-bordered" >
        <thead class="table-primary fw-bolder nrg-table-header">
        <tr>
          <td>#</td>
          <td [innerHTML]="'caption.createdOn' | translate" ></td>
          <td [innerHtml]="'caption.user_details' | translate" ></td>
          <td [innerHtml]="'caption.brand' | translate" ></td>
          <td [innerHtml]="'caption.profile' | translate" ></td>
          <td [innerHtml]="'caption.points' | translate" ></td>
          <td [innerHtml]="'caption.role' | translate" ></td>
          <td [innerHtml]="'caption.isNew' | translate" ></td>
          <td [innerHtml]="('caption.isEnabled' | translate)
          +' / ' +('caption.isAccNonLocked' | translate)
          +' / ' +('caption.isAccNonExpired' | translate)
          +' / ' +('caption.isPasswordNonExpired' | translate)" ></td>
          <td [innerHtml]="'caption.approval' | translate" ></td>
          <td [innerHtml]="'caption.lastPasswordUpdate' | translate" ></td>
        </tr>
        </thead>

        <tbody class="nrg-table-body">
          @for(rec of filteredRecUsers; let i=$index; track rec.id ) {
            <tr>
              <td>{{ i+1 }}</td>
              <td [innerHTML]=" rec.createdOn | localDateformat:'YYYY-MM-DD hh:mm A' " ></td>
              <td>
                <a (click)="onClickUser(rec)" href="javascript:void(0)" [innerHTML]=" rec.email"></a>
                <br>
                {{ rec.fullName }}
                <br>
                [ {{ rec.company }} ]
                <br>
                <i>{{ rec.mobile }}</i>
              </td>
              <td [innerHTML]=" rec.brand "></td>
              <td [innerHTML]=" rec.profile "></td>
              <td [innerHTML]=" rec.points|number_commas "></td>
              <td [innerHTML]=" getRoleName(rec) "></td>
              <td [innerHTML]=" rec.isNew ? '<span class=special-blue>Y</span>' : 'N' "></td>
              <td [innerHTML]=" (rec.isEnabled ? 'Y' : '<span class=special-red>N</span>')
                +' / ' +(rec.isAccNonLocked ? 'Y' : '<span class=special-red>N</span>')
                +' / ' +(rec.isAccNonExpired ? 'Y' : '<span class=special-red>N</span>')
                +' / ' +(rec.isPasswordNonExpired ? 'Y' : '<span class=special-red>N</span>')
                "></td>
              <td class="text-sm-start" [innerHTML]=" rec.approval "></td>
              <td [innerHTML]=" rec.lastPasswordUpdate | localDateformat:'YYYY-MM-DD hh:mm A' " ></td>
            </tr>
          }
        </tbody>
      </table>
    </div>

  </div>

</form>

<app-user-edit *ngIf="showEditForm"
               [user]="userForEdit"
               [profileOptions]="profileOptions"
               (closeEdit)="onCloseEditForm($event)"
/>
