<form [formGroup]="form" class="form-signin text-center" >

  <h2 class="form-signin-heading">{{ 'caption.update_profile' | translate}}</h2>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert"
       [innerHTML]="error_msg"></div>

  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg"></div>

  <div class="text-start d-inline-block" >
    <div class="form-field">
      <mat-form-field class="form-field-full">
        <mat-label class="sr-only" [innerHTML]="'caption.fullname' | translate"></mat-label>
        <input matInput type="text" id="fullName" formControlName="fullName"
               placeholder="{{ 'caption.fullname' | translate }}"
               minlength="{{ _globals.minFieldNameLength }}"
               maxlength="{{ _globals.maxFieldLength }}"
        >
        <mat-icon *ngIf="submitted && f['fullName']['errors']" matIconSuffix>error</mat-icon>

        @if(submitted && f['fullName']['errors']){
          <mat-error *ngIf="f['fullName']['errors']['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
          <mat-error *ngIf="f['fullName']['errors']['minlength']">{{ 'error.field_min_len' | translate: {n: f['fullName']['errors']['minlength']['requiredLength']} }}</mat-error>
        }
      </mat-form-field>
    </div>

    <div class="form-field">
      <mat-form-field class="form-field-full">
        <mat-label class="sr-only" [innerHTML]="'caption.mobile' | translate"></mat-label>
        <input matInput type="text" id="mobile" formControlName="mobile"
               placeholder="{{ 'caption.mobile' | translate }}"
               minlength="{{ _globals.minFieldMobileLength }}"
               maxlength="{{ _globals.maxFieldLength }}"
        >
        <mat-icon *ngIf="submitted && f['mobile']['errors']" matIconSuffix>error</mat-icon>

        @if(submitted && f['mobile']['errors']){
          <mat-error *ngIf="f['mobile']['errors']['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
          <mat-error *ngIf="f['mobile']['errors']['minlength']">{{ 'error.field_min_len' | translate: {n: f['mobile']['errors']['minlength']['requiredLength']} }}</mat-error>
        }
      </mat-form-field>
    </div>

    <div class="form-field">
      <mat-form-field class="form-field-full">
        <mat-label class="sr-only" [innerHTML]="'caption.company' | translate"></mat-label>
        <input matInput type="text" id="company" formControlName="company"
               placeholder="{{ 'caption.company' | translate }}"
               maxlength="{{ _globals.maxFieldLength }}"
        >
        <mat-icon *ngIf="submitted && f['company']['errors']" matIconSuffix>error</mat-icon>

        @if(submitted && f['company']['errors']){
          <mat-error *ngIf="f['company']['errors']['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
        }
      </mat-form-field>
    </div>
  </div>

  <div class="form-group">
    <button [disabled]="loading" class="btn btn-sm btn-primary btn-block" type="button"
      (click)="onSubmit()">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ 'caption.submit' | translate }}
    </button>
  </div>

</form>

