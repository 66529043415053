import {Component, Input, OnInit} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {
  AbstractControl,
  FormBuilder, FormControl,
  FormGroup,
  ReactiveFormsModule, RequiredValidator,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {globals} from "../../conf/globals";
import {AppComponent} from "../app.component";
import {ActivatedRoute, Router} from "@angular/router";
import {ValidateConfirmPassword, ValidatePasswordSpecialChars} from "../../helpers/password.validator";
import {first, window} from "rxjs";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    NgClass,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.css'
})
export class ChangePasswordComponent implements OnInit {
  error_msg='';
  info_msg ='';
  form: FormGroup;
  loading = false;
  submitted = false;
  title = 'caption.change_password';

  // 'newuser' or 'resetpassword'
  urlSubPath =this.route.snapshot.url[0]?.path;

  // show verification code input box
  needVerifyCode =false;

  passwordVisible ={password: false, confirm_password: false};

  get f() { return this.form.controls; }

  protected readonly _globals = globals;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute
  ) {
    // console.log('>>> ChangePasswordComponent.constructor(): pathname=%s, a=%s',
    //   route.snapshot.url[0].path, route.snapshot.url[0].parameters['a']);

    this.form = this.formBuilder.group({
      // 'email': ['', Validators.compose(
      //   [ Validators.required, Validators.email, Validators.maxLength(globals.maxFieldLength)]
      // )],
      'password': ['', Validators.compose(
        [ Validators.required, Validators.minLength(globals.minPasswordLength), ]
      )],
      'confirm_password': ['', Validators.compose(
        [ Validators.required ]
      )],
    }, { validators: [ ValidatePasswordSpecialChars(), ValidateConfirmPassword() ] });

  }

  onClickRevealPassword(event:any, name:string) {
    // console.log('onClickRevealPassword: %o', event?.target);

    event.preventDefault();
    // Prevent revealing the password when enter button is pressed.
    if (event.target instanceof HTMLSpanElement) {
      // @ts-ignore
      this.passwordVisible[name] = !this.passwordVisible[name];
    }
  }

  onSubmit() {
    this.submitted =true;
    this.info_msg = this.error_msg = '';

    if(this.form.invalid) {
      return;
    }

    this.loading =true;

    let userDetails:any = {password: this.f['password']?.value};
    if(this.needVerifyCode) {
      userDetails.email = this.f['email']?.value;
    } else {
      userDetails.email = this.appComponent.user.email;
    }

    const key = this.route.snapshot.queryParams['k'];
    const verificationCode = this.f['verificationCode']?.['value'];

    // console.log("updateUser: key='%s', verificationCode='%s', user=%o", key, verificationCode, userDetails)

    const firstPassword = this.urlSubPath === 'newuser';
    this.appComponent.userService.updateUser(userDetails, key, verificationCode, firstPassword)
      .pipe(first()).subscribe({
        next: (res) => {
          // done
          this.loading = false;
          // console.log('>>>### ok: %o', res);

          if(res.ok) {
            this.info_msg = this.appComponent.translate.instant(res.result);

            this.form.reset();
            this.submitted =false;
          } else {
            this.error_msg = this.appComponent.translate.instant(res.result);
          }
        }, error: err => {
          this.loading = false;
          this.error_msg = this.appComponent.translate.instant('error.user_update_failed');
        }
      }
    );

  }

  ngOnInit(): void {
    // console.log('>>> ChangePasswordComponent.ngOnInit(): pathname=%s, a=%s',
    //   this.route.snapshot.url[0].path, this.route.snapshot.queryParams['a']);

    if(this.urlSubPath === 'newuser' && this.route.snapshot.queryParams['a'] === 'newuser'
      || this.urlSubPath === 'resetpasswd') {

      switch (this.urlSubPath) {
      case 'newuser':

        // new user 1st-time change password
        this.title = 'caption.welcome_newuser';
        break;

      case 'resetpasswd':
        this.title = 'caption.reset_password';
        break;

      }

      this.needVerifyCode =true;

      this.form.addControl('verificationCode', new FormControl('', Validators.required));
      this.form.addControl('email', new FormControl('',
        [ Validators.required, Validators.email, Validators.maxLength(globals.maxFieldLength)]))
    }
  }
}
