import {BehaviorSubject, first, map, Observable, pipe} from 'rxjs';
import {IResultUserResp, IResultUsersResp, IUser, User} from "../models";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {IResultResp} from "../models";
import {Router} from "@angular/router";
import {FilterConditions} from "./index";
import {SystemService} from "./SystemService";

@Injectable({ providedIn: 'root' })
export class UserService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private catalogLinkSubject: BehaviorSubject<string>;
  public catalogLink: Observable<string>;

  constructor(
    private http:HttpClient,
    private router: Router,
    private systemService: SystemService
  ) {

    // console.log('>>> UserService.constructor:')

    // const js_user = localStorage.getItem('currentUser');
    // let user :IUser | null = null;
    // if(js_user) user = JSON.parse(js_user);

    this.currentUserSubject = new BehaviorSubject<User>(new User());
    this.currentUser = this.currentUserSubject.asObservable();

    this.catalogLinkSubject = new BehaviorSubject<string>('');
    this.catalogLink = this.catalogLinkSubject.asObservable();
  }

  public get currentUserValue() : User {
    // console.log("### user=%o", this.currentUserSubject.value);

    return this.currentUserSubject.value;
  }

  public get currentCatlogLinkValue() : string {
    return this.catalogLinkSubject.value;
  }

  loadCatalogLink() {
    let user = this.currentUserValue;
    // console.log('>>> user-navbar: loadCatalogLink: email=%s, role_admin=%s',
    //   this.user.email, this.user.hasRole('ROLE_ADMIN'));

    if(user.email && !user.hasRole('ROLE_ADMIN')) {
      this.systemService.getSetting(`brand_${user.brand}_catalog_link`)
        .pipe(first()).subscribe({
        next: (res) => {
          this.catalogLinkSubject.next(res.result);
        }
      })
    } else {
      this.catalogLinkSubject.next('');
    }
  }


  login(email: string, password: string)  {
    // const user: User = new User({
    //   email : email,
    //   brand: '', // '*', 'JABRA'
    //   isAdmin: true,
    //   isSuperuser: false,
    //   isEnabled: true,
    //   isAccNonExpired: true,
    //   isAccNonLocked: true
    // } as Partial<IUser>);

    return this.http.post<any>(environment.api_url +'/signin',
      { email: email, password: password}, {withCredentials: true})
      .pipe(map(res => {
        let user:User = new User(res.user);
        // user.token = res.token;

        // localStorage.setItem('currentUser', JSON.stringify(res.user));
        this.currentUserSubject.next(user);
        this.loadCatalogLink();

        return user;
      }));

    // console.log(">>> login user=%o", user);
  }

  checkSession() {
    // check if there's any established session available from server
    return this.http.get(environment.api_url +'/checkSession', {withCredentials: true})
      .pipe(map<any, User>(res => {
        let user:User = new User(res.user);

        // console.log('>>> checkSession: %s points %s', user.email, user.points);

        this.currentUserSubject.next(user);
        this.loadCatalogLink();
        return user;
      }));
  }

  register(email: string, fullName: string, mobile: string, company: string, verify_code:string, enc_verify_str: string) {
    return this.http.post<any>(environment.api_url +'/registerUser',
      { href: window.location.href, email: email, fullName: fullName, mobile, company: company, verify_code: verify_code, enc_verify_str: enc_verify_str},
      {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        console.log('>>> register: %o', res);

        // return: { status: ok, result: msg }. msg: id for i18n. It needs '{email: xxxx}' as i18n's parameter
        return res;
      }));
  }

  approveNewUser(email: string, action:string, reason:string) {
    return this.http.post<any>(environment.api_url +'/approveNewUser',
      { email: email, action: action, reason:  reason},
      {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        console.log('>>> approveNewUser: %o', res);

        // return: { status: ok, result: msg }. msg: id for i18n.
        return res;
      }));
  }

  kickoutCurrentUser() {
    // console.log('>>> UserService.kickoutCurrentUser()');

    let user:User = new User();
    this.currentUserSubject.next(user);
    this.router.navigateByUrl('/login', {skipLocationChange:true});
    this.loadCatalogLink();

  }
  logout() {
    return this.http.post(environment.api_url +'/logout', { dummy: ''}, {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        // console.log('>>> POST logout(): %o', res);
        this.kickoutCurrentUser();

        return res;
      }));

  }

  sendVerificationCodeEmail(toEmail: string) {
    return this.http.post(environment.api_url +'/sendVerificationEmail', {toEmail: toEmail}, {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        // console.log('>>> sendVerificationCodeEmail: %o', res);

        return res;
      }));

  }

  forgotPassword(toEmail: string) {
    return this.http.post(environment.api_url +'/forgotPassword', {toEmail: toEmail}, {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        // console.log('>>> sendVerificationCodeEmail: %o', res);

        return res;
      }));

  }

  // conditions? : [{ var: 'isNew', op: '=', value: true}, { var: 'brand', op: '=', value: 'JABRA' } ]
  getAllUsers(conditons? :FilterConditions[], needIdField? : boolean) {
    // console.log('>>> getUsers: conditions=%o, needIdField=%s', conditons, needIdField);

    return this.http.post(environment.api_url +'/getAllUsers',
      {conditions: conditons ?? [], needIdField: needIdField ?? false},
      {withCredentials: true})
      .pipe(map<any, IResultUsersResp>(res => {
        // console.log('>>> sendVerificationCodeEmail: %o', res);
        return res;
      }));
  }

  // update user
  // key: server generated key for 1st-time user password change
  updateUser(user: Partial<IUser>, key? :string | undefined, verificationCode? :string | undefined, firstPassword? :boolean) {
    // console.log("api: updateUser: key='%s', verificationCode='%s', user=%o", key, verificationCode, user)

    return this.http.post<any>(environment.api_url +'/updateUser',
      { user: user, key: key || '', verificationCode: verificationCode || '', firstPassword: firstPassword},
      {withCredentials: true})
      .pipe(map<any, IResultUserResp>(res => {
        // console.log('>>> updateUser: %o', res);

        // return: { status: ok, result: msg, user: <updated user details> }. msg: id for i18n.
        return res;
      }));
  }

}
