import { Routes } from '@angular/router';
import {WelcomeComponent} from "./welcome/welcome.component";
import {authGuard} from "../helpers/auth.guard";
import {LoginComponent} from "./login/login.component";
import {RegisterUserComponent} from "./register-user/register-user.component";
import {ApproveNewUserComponent} from "./approve-new-user/approve-new-user.component";
import {ChangePasswordComponent} from "./change-password/change-password.component";
import {AddPointsComponent} from "./add-points/add-points.component";
import {RedeemComponent} from "./redeem/redeem.component";
import {UpdateGiftsComponent} from "./update-gifts/update-gifts.component";
import {AppsettingsComponent} from "./appsettings/appsettings.component";
import {AuditLogComponent} from "./audit-log/audit-log.component";
import {UserAdminComponent} from "./user-admin/user-admin.component";
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";
import {ProfileComponent} from "./profile/profile.component";

export const routes: Routes = [
  { path: '', component: WelcomeComponent, canActivate: [authGuard('ROLE_USER')] },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register_user', component: RegisterUserComponent },
  { path: 'forgot_password', component: ForgotPasswordComponent },
  { path: 'newuser', component: ChangePasswordComponent },
  { path: 'resetpasswd', component: ChangePasswordComponent },
  { path: 'password', component: ChangePasswordComponent, canActivate: [authGuard('ROLE_USER')] },
  { path: 'profile', component: ProfileComponent, canActivate: [authGuard('ROLE_USER')] },
  { path: 'addpoints', component: AddPointsComponent, canActivate: [authGuard('ROLE_USER')] },
  { path: 'update_gift', component: UpdateGiftsComponent, canActivate: [authGuard('ROLE_ADMIN')] },
  { path: 'redeem', component: RedeemComponent, canActivate: [authGuard('ROLE_USER')] },
  { path: 'approve_new_user', component: ApproveNewUserComponent, canActivate: [authGuard('ROLE_ADMIN')] },
  { path: 'appsettings', component: AppsettingsComponent, canActivate: [authGuard('ROLE_SUPERUSER')] },
  { path: 'auditLog', component: AuditLogComponent, canActivate: [authGuard('ROLE_SUPERUSER')] },
  { path: 'userAdmin', component: UserAdminComponent, canActivate: [authGuard('ROLE_SUPERUSER')] },
  { path: '**', redirectTo: '/' }
];
