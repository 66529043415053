<div class="mt-3 text-center" >
  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert"
       [innerHTML]="error_msg">
  </div>

  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg">
  </div>
</div>

<app-add-points-uploadinv *ngIf="!user.hasRole('ROLE_ADMIN')"
  [user]="user"
  (userEvent)="onUserEvent($event)"
  >
</app-add-points-uploadinv>

<hr class="my-3" />

<form class="mt-1 text-center" [formGroup]="form" >
  <div class="row g-2 text-sm-start align-items-center" >
    <div class="col-auto">
    <mat-form-field class="recFilter_select">
      <select matNativeControl id="recFilter" formControlName="recFilter"
              (change)="onChangeRecFilter($event)">
        <option *ngFor="let option of recFilterOptions" [value]="option.val" [innerHTML]="option.display"></option>
      </select>
    </mat-form-field>
    </div>

    <div class="col-auto">
    <mat-form-field *ngIf="brandFilterOptions.length > 1" class="mx-3">
      <select id="brandFilter" formControlName="brandFilter"
              matNativeControl
              (change)="onChangeBrandFilter($event)">
        <option *ngFor="let option of brandFilterOptions" [value]="option.val" [innerHTML]="option.display"></option>
      </select>
    </mat-form-field>
    </div>

    @if(user.hasRole('ROLE_ADMIN')) {
      <div class="col-auto">
      <mat-form-field>
        <mat-label class="sr-only" [innerHTML]="'caption.points_rec_id' | translate"></mat-label>
        <input matInput type="text" id="email" formControlName="points_rec_id"
               placeholder="{{ 'caption.points_rec_id' | translate }}"
               maxlength="{{ _globals.maxFieldLength }}"
               (keydown.enter)="onChangePointsRecIdFilter()"
        >
      </mat-form-field>
      </div>
    }

  </div>

  <div formArrayName="aliases" *ngIf="pointsRecs" class="mt-2">
    <table class="table table-secondary table-bordered" >
      <thead class="table-primary fw-bold nrg-table-header">
      <tr>
        <td>#</td>
        <td >{{ 'caption.createdOn' | translate}}</td>
        <td *ngIf="user.hasRole('ROLE_ADMIN')" [innerHTML]="'caption.email_name_mobile' | translate" ></td>
        <td *ngIf="user.hasRole('ROLE_ADMIN')" >{{ 'caption.company' | translate}}</td>
        <td *ngIf="user.hasRole('ROLE_ADMIN')" >{{ 'caption.brand' | translate}}</td>
        <td *ngIf="user.hasRole('ROLE_ADMIN')" >{{ 'caption.added_by' | translate}}</td>
        <td >{{ 'caption.invoices_or_Meeting_or_Request' | translate}}</td>
        <td >{{ 'caption.points' | translate}}</td>
        <td *ngIf="user.hasRole('ROLE_ADMIN')" >{{ 'caption.remarks' | translate}}</td>
      </tr>
      </thead>

      <tbody class="nrg-table-body">
        @for(rec of pointsRecs; let i=$index; track rec.id ) {
          <tr>
            <td>{{ i+1 }}</td>
            <td>{{ rec.createdOn | localDateformat:'YYYY-MM-DD hh:mm A' }}</td>
            <td *ngIf="user.hasRole('ROLE_ADMIN')" [innerHTML]="rec.email +'<br><b>' +rec.fullName +'</b><br><i>' +rec.mobile +'</i>'" ></td>
            <td *ngIf="user.hasRole('ROLE_ADMIN')" >{{ rec.company}}</td>
            <td *ngIf="user.hasRole('ROLE_ADMIN')" >{{ rec.brand}}</td>
            <td *ngIf="user.hasRole('ROLE_ADMIN')" >
              {{ rec.added_by}}
              @if(rec.added_on) {
                {{ rec.added_on | localDateformat:'(YYYY-MM-DD hh:mm A)' }}
              }
            </td>

            @if(rec.invoices) {
              <td>
                <a (click)="onClickInvoice(i)" href="javascript:void(0)" >{{ ('caption.invoice' | translate) + (rec.invoice_number ? ' ' +rec.invoice_number : '')  }}</a>

                @if(rec.added_on) {
                  @if(!rec.disputed_on) {
                    @if(!user.hasRole('ROLE_ADMIN')) {
                      <button [disabled]="loading" class="btn btn-sm ms-3 btn-outline-danger btn-block"
                              type="button" (click)="onClickWriteDispute(i)">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        {{ 'caption.dispute' | translate }}
                      </button>
                    }
                  } @else{
                    <br>{{ ('caption.disputed_on'|translate) +' ' +(rec.disputed_on|localDateformat) }}

                    @if(user.hasRole('ROLE_ADMIN')) {
                      <button [disabled]="loading" class="btn btn-sm ms-3 btn-outline-danger btn-block"
                              type="button" (click)="onClickWriteDispute(i)">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        {{ 'caption.settle_dispute' | translate }}
                      </button>
                    }
                  }
                }
              </td>
            } @else if (rec.meetingDate) {
              <td [innerHTML]="
                ('caption.meeting_date'|translate)
                  + '&nbsp; : &nbsp;'
                  + (rec.meetingDate| localDateformat:'YYYY-MM-DD (ddd) hh:mm A')
                  + '<br>'
                  + ('caption.salesman'|translate)
                  + '&nbsp; : &nbsp;'
                  + (rec.salesman | removeAngleChars)
                "></td>
            } @else if (rec.userRequest) {
              <td [innerHTML]=" rec.userRequest | removeAngleChars "></td>
            }

            <td>{{ rec.points}}</td>
            <td *ngIf="user.hasRole('ROLE_ADMIN')">
              @if(rec.added_by) {
                <a (click)="onClickRemark(i.toString())" href="javascript:void(0)" >{{ (showRemarks[i.toString()] ? 'caption.hide_remarks' : 'caption.show_remarks') | translate }}</a>
              } @else {
                @if(user.hasRole('ROLE_ADMIN') && (rec.meetingDate || rec.invoices )) {
                  <a (click)="onClickRemark(i.toString())" href="javascript:void(0)" >{{ 'caption.grant_points' | translate }}</a>
                } @else if(user.hasRole('ROLE_ADMIN') && rec.userRequest) {
                  <a (click)="onClickRemark(i.toString())" href="javascript:void(0)" >{{ 'caption.add_remarks' | translate }}</a>
                } @else {
                  <div class="text-sm-center fw-light" [innerHTML]="'status.empty' | translate"></div>
                }
              }
            </td>
          </tr>

          <tr *ngIf="showRemarks[i.toString()] || showDispute[i.toString()]" [formGroupName]="i" >
            @if(showRemarks[i.toString()]) {
              @if(user.hasRole('ROLE_ADMIN')) {
                <td colspan="9">
                  @if(!rec.added_by) {
                    <div class="row g-1 align-items-center w-75" style="display: inline-block" >
                      <div class="col-auto">
                        <div class="d-inline-block">
                          <label for="alias-points-{{i}}" class="sr-only">{{ 'caption.set_points' | translate }}</label>
                          <input type="text" id="alias-points-{{i}}" formControlName="points" class="form-control form-control-sm m-1"

                                 [placeholder]="'caption.set_points' | translate"
                                 [value]="rec.points?.toString()"
                                 maxlength="10"
                          />
                          <div *ngIf="submitted && aliases.at(i).get('points')?.['errors']" class="invalid-feedback error-box d-block">
                            <div *ngIf="submitted && aliases.at(i).get('points')?.['errors']?.['required']"
                                 [innerHTML]="'error.must_fill_in' | translate"></div>
                            <div *ngIf="submitted && aliases.at(i).get('points')?.['errors']?.['pattern']"
                                 [innerHTML]="'error.must_be_whole_number' | translate"></div>
                          </div>
                        </div>

                        <div>
                          <label for="alias-remarks-{{i}}" class="sr-only">{{ 'caption.write_remarks' | translate }}</label>
                          <textarea id="alias-remarks-{{i}}" formControlName="remarks" class="form-control form-control-sm mb-1"
                                    style="width: 100%"
                                    [placeholder]="'caption.write_remarks' | translate" ></textarea>
                        </div>
                      </div>
                      <div class="col-auto">
                        <button [disabled]="loading" class="btn btn-sm ms-3 btn-secondary btn-block"
                                type="button" (click)="onClickUpdatePoints(i)">
                          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                          {{ 'caption.set_points' | translate }}
                        </button>
                      </div>
                    </div>
                  } @else {
                    <div class="text-bg-primary text-sm-start" [innerHTML]="rec.remarks |stringReplace:'\n':'<br>\n'"></div>
                  }
                </td>
              } @else {
                <td colspan="5">
                  <div class="text-info" [innerHTML]="rec.remarks |stringReplace:'\n':'<br>\n'"></div>
                </td>
              }
            }

            @if(showDispute[i.toString()]) {
              <td [attr.colspan]="user.hasRole('ROLE_ADMIN') ? '9' : '5'">
                <div class="row g-1 align-items-center w-75" style="display: inline-block" >
                  <div class="col-auto">
                    <label for="alias-dispute-{{i}}" class="sr-only"
                      [innerHTML]="(user.hasRole('ROLE_ADMIN') ? 'caption.write_settle_dispute' : 'caption.write_dispute') | translate"></label>
                    <textarea id="alias-dispute-{{i}}" formControlName="dispute_reason" class="form-control form-control-sm mb-1"
                              maxlength="{{ _globals.maxReasonLength }}"
                              style="width: 100%"
                              [placeholder]="'caption.reason' | translate" ></textarea>
                  </div>

                  <div *ngIf="submitted && aliases.at(i).get('dispute_reason')?.['errors']" class="invalid-feedback error-box d-block">
                    <div *ngIf="submitted && aliases.at(i).get('dispute_reason')?.['errors']?.['required']"
                         [innerHTML]="'error.must_fill_in' | translate"></div>
                  </div>

                  <div class="col-auto">
                    <button [disabled]="loading" class="btn btn-sm ms-3 btn-secondary btn-block"
                            type="button" (click)="onClickUpdateDispute(i)">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                      {{ 'caption.submit' | translate }}
                    </button>
                  </div>
                </div>
              </td>
            }
          </tr>
        }

      </tbody>
    </table>
  </div>
</form>
