import {AbstractControl, FormGroup} from "@angular/forms";

export function clearAllFormControlsErrors(form : any) {
  Object.keys(form.controls).forEach(key => {
    const errors =form.get(key)?.errors;
    if(errors != null) {
      Object.keys(errors).forEach(keyError => {
        form.controls[key].setErrors(null)
      })
    }
  })

}
