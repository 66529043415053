import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {getRoleName, IUser} from "../../models";
import {NgForOf, NgIf} from "@angular/common";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {AppComponent} from "../app.component";
import {Router} from "@angular/router";
import {globals} from "../../conf/globals";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {first} from "rxjs";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {ValidateConfirmPassword, ValidatePasswordSpecialChars} from "../../helpers/password.validator";
import {NumberCommasPipe} from "../../helpers/number_commas.pipe";

@Component({
  selector: 'app-user-edit',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    NgForOf,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './user-edit.component.html',
  styleUrl: './user-edit.component.css'
})
export class UserEditComponent implements OnInit {
  @Input() user!: Partial<IUser>|undefined;
  @Input() profileOptions!: {val:string, display:string}[];
  @Output() closeEdit = new EventEmitter<boolean>();

  error_msg = '';
  info_msg = '';
  form: FormGroup = new FormGroup<any>({});

  loading = false;
  submitted = false;

  passwordVisible ={password: false, confirm_password: false};

  get f() {return this.form.controls;}

  protected readonly _globals = globals;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private router: Router
  ) {

  }

  ngOnInit() {
    console.log('ngOnInit: email=%s', this.user?.email);

    this.form = this.formBuilder.group({
      email: [this.user?.email],
      fullName: [this.user?.fullName, Validators.required],
      company: [this.user?.company, Validators.required],
      brand: [this.user?.brand],
      isEnabled: [this.user?.isEnabled ? 'Y' : 'N'],
      isNew: [this.user?.isNew ? 'Y' : 'N'],
      role: [getRoleName(this.user)],
      isAdmin: [this.user?.isAdmin],
      isSuperuser: [this.user?.isSuperuser],
      points: [NumberCommasPipe.instant(this.user?.points)],
      profile: [this.user?.profile ],
      isAccNonLocked: [this.user?.isAccNonLocked ? 'Y' : 'N'],
      isAccNonExpired: [this.user?.isAccNonExpired ? 'Y' : 'N'],
      isPasswordNonExpired: [this.user?.isPasswordNonExpired ? 'Y' : 'N'],
      approval: [this.user?.approval],
      mobile: [this.user?.mobile, [Validators.required, Validators.minLength(this._globals.minFieldMobileLength)]],
      password: [''],
      confirm_password: [''],
    }, { validators:
        [ this.formValidator(),
          ValidatePasswordSpecialChars(true),
          ValidateConfirmPassword(true)
        ]
    });

  }

  onChangeRole(event:any) {
    console.log('onChangeRole: event = %s', event.target.value);

    this.f['isAdmin']?.setValue(event.target.value === 'ROLE_ADMIN');
    this.f['isSuperuser']?.setValue(event.target.value === 'ROLE_SUPERUSER');
  }

  // get getRole(): string {
  //   if(this.f['isSuperuser']?.value) return 'ROLE_SUPERUSER';
  //   if(this.f['isAdmin']?.value) return 'ROLE_ADMIN';
  //   if(this.f['email']?.value) return 'ROLE_USER';
  //
  //   return '';
  // }

  onClickRevealPassword(event:any, name:string) {
    // console.log('onClickRevealPassword: %o', event?.target);

    event.preventDefault();
    // Prevent revealing the password when enter button is pressed.
    if (event.target instanceof HTMLSpanElement) {
      // @ts-ignore
      this.passwordVisible[name] = !this.passwordVisible[name];
    }
  }

  onCloseEdit() {
    this.closeEdit.emit(true);
  }

  formValidator() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if(!(control instanceof FormGroup)) return null;

      if(!this.f['profile']?.value) {
        if (this.f['isAdmin']?.value || this.f['isSuperuser']?.value ) {
          this.f['profile']?.setErrors({ 'required': {value: true}});
        } else {
          this.f['profile']?.setErrors(null);
        }
      }

      if(!this.f['brand']?.value) {
        if (this.f['isAdmin']?.value || this.f['isSuperuser']?.value) {
          this.f['brand']?.setErrors(null);
        } else {
          this.f['brand']?.setErrors({ 'required': {value: true}} );
        }
      }

      // points checking
      if(!/^[\d,]+$/.test(this.f['points']?.value)) {
        if (this.f['isAdmin']?.value || this.f['isSuperuser']?.value) {
          this.f['points']?.setErrors(null);
        } else {
          this.f['points']?.setErrors({ pattern: {value: true}});
        }
      }

      return null;
    }
  };

  onClickUpdate(event: any) {
    this.submitted =true;

    this.form.markAllAsTouched();

    let item = 'fullName';
    const val = this.user?.[item as keyof IUser];
    const typeString =typeof val;
    console.log('onClickUpdate: %s %s', val, typeString);

    if(this.form.invalid) return;

    //debug
    // if(this.submitted) {
    //   return;
    // }

    this.loading =true;

    let userDetails: any = {
      email: this.user?.email
    };

    for(let item of ['fullName', 'company', 'profile', 'brand', 'approval', 'isAdmin',
      'isSuperuser', 'isEnabled', 'isNew', 'isAccNonLocked', 'isAccNonExpired', 'isPasswordNonExpired', 'points',
      'password', 'mobile'
      ]) {

        switch (typeof this.user?.[item as keyof IUser]) {
          case 'number':
            // remove all white spaces and commas
            userDetails[item] = Number(this.f[item]?.value.replace(/[\s,]/g, ''))
            break;

          case 'boolean':
            userDetails[item] = this.f[item]?.value === 'Y';
            break;

          case 'string':
          default:
            userDetails[item] = this.f[item]?.value;
            break;
        }
    }

    // update role
    if(this.f['role']?.dirty) {
      userDetails.isSuperuser =this.f['role']?.value === 'ROLE_SUPERUSER';
      userDetails.isAdmin = this.f['role']?.value === 'ROLE_ADMIN';
    }

    this.appComponent.userService.updateUser(userDetails)
      .pipe(first()).subscribe({
        next: (res) => {
          // done
          this.loading = false;
          if(res.ok) {
            this.info_msg = this.appComponent.translate.instant(res.result);
          } else {
            this.error_msg = this.appComponent.translate.instant(res.result);
          }
          this.submitted = this.loading = false;
          window.scrollTo(0, 0);
        }, error: err => {
          this.submitted = this.loading = false;
          this.error_msg = this.appComponent.translate.instant('error.user_update_failed');
        }
      }
    );

  }

  onUpdatePoints(value:string) {
    this.f['points'].setValue(NumberCommasPipe.instant(value))
  }

}
