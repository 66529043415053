

// path: 'user_invoices/erichklam@gmail.com/invoice.pdf'
// return: '.pdf'
export function captureFileExt(path:string): string {
  if(!path) return '';

  let matches: RegExpMatchArray | null =null;
  try {
    const re = /(\.[^\.]+)$/;
    matches = path.match(re);
  }catch (e) {console.error(e)}

  return matches && matches.length > 1 ? matches[1] : '';
}
